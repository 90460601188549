import React, { useEffect } from "react";
import { Modal, Row, Col, Button, Input, Form } from "antd";
import "./popup.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, customGroup } from "@src/redux/confirm";
import WaringIcon from "@src/styles/icon/notify_icon.svg";

import {
  addGroup,
  updateGroupId,
  deleteGroup,
  updateGroup,
} from "@src/redux/setting";
import Paragraph from "antd/lib/typography/Paragraph";
import { lang, COMMON_CONSTANT } from "@src/constants/common.const";
import { setIsUpdated } from "@src/redux/request";

function Confirm() {
  const dispatch = useDispatch();
  let { open, title, typeConfirm, typeCustom, redirectUrl, data } = useSelector(
    (state) => state.confirm
  );
  let { groupId, allGroupsName, arrayName } = useSelector(
    (state) => state.setting
  );
  const history = useHistory();
  const [formGroupName] = Form.useForm();
  let regExp = '^[^<>{}"/|;:.,~!?@#$%^=&*\\]\\\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©_+]*$';
  const renderIcon = () => {
    return <img style={{ height: "30px" }} alt="logo.png" src={WaringIcon} />;
  };
  useEffect(() => {
    data && formGroupName.setFieldsValue({ name: data.text });
  }, [data, formGroupName]);
  const addGroups = () => {
    if (
      !formGroupName.getFieldsError()[0].errors.length &&
      formGroupName.getFieldValue("name") &&
      arrayName.indexOf(formGroupName.getFieldValue("name")) === -1
    ) {
      dispatch(addGroup(formGroupName.getFieldValue("name")));
      closeDialog();
    }
  };
  const deleteGroupChecklist = () => {
    dispatch(deleteGroup(groupId));
    dispatch(updateGroupId(allGroupsName[0].id));
    closeDialog();
  };
  const updateGroupChecklist = () => {
    if (
      !formGroupName.getFieldsError()[0].errors.length &&
      formGroupName.getFieldValue("name") &&
      arrayName.indexOf(formGroupName.getFieldValue("name")) === -1
    ) {
      dispatch(updateGroup(groupId, formGroupName.getFieldValue("name")));
      closeDialog();
    }
  };
  const checkSameName = async (rule, value) => {
    if (arrayName.indexOf(value) !== -1) {
      throw new Error(lang.GROUP_CL_HAS_EXISTED);
    }
  };

  const renderButtonRight = () => {
    if (typeCustom) {
      switch (typeCustom) {
        case "edit":
          return (
            <Button onClick={openModalDelete} className="button button--error">
              {lang.DELETE}
            </Button>
          );
        case "delete":
          return (
            <Button
              onClick={closeDialog}
              className="button button--outline --todo--"
            >
              {lang.CLOSE}
            </Button>
          );
        default:
          return <></>;
      }
    }
  };

  const renderButtonLeft = () => {
    if (typeConfirm) {
      switch (typeConfirm) {
        case "warning":
          return (
            <Button onClick={redirectPage} className="button button--warning">
              {lang.OK}
            </Button>
          );
        case "success":
          return (
            <Button onClick={redirectPage} className="button button--success">
              {lang.OK}
            </Button>
          );
        case "error":
          return (
            <Button onClick={redirectPage} className="button button--error">
              {lang.OK}
            </Button>
          );

        default:
          return <></>;
      }
    }
    if (typeCustom) {
      switch (typeCustom) {
        case "add":
          return (
            <Button
              htmlType="submit"
              className="button button--info"
              onClick={() => addGroups()}
            >
              {COMMON_CONSTANT.ADD}
            </Button>
          );
        case "edit":
          return (
            <Button
              htmlType="submit"
              className="button button--info"
              onClick={() => updateGroupChecklist()}
            >
              {lang.SAVE}
            </Button>
          );
        case "delete":
          return (
            <Button
              htmlType="submit"
              className="button button--error"
              onClick={() => deleteGroupChecklist()}
            >
              {lang.OK}
            </Button>
          );
        default:
          return <></>;
      }
    }
  };
  const openModalDelete = () => {
    dispatch(closeModal());
    dispatch(
      customGroup({
        open: true,
        title: COMMON_CONSTANT.TITLE_DEL_GROUP,
        typeCustom: "delete",
        data: {
          text: data.text,
        },
      })
    );
  };
  const renderCustomGroup = () => {
    if (typeCustom !== "delete") {
      return (
        <Form name="input" form={formGroupName}>
          <Row align="middle" justify="center" gutter={[8, 8]}>
            <Col span={24}>
              <Row justify="center">
                {typeCustom ? (
                  <Col className="titleCustomGroup">{title}</Col>
                ) : (
                  renderIcon()
                )}
              </Row>
            </Col>
            <Col className="text-weight-600" span={24}>
              {COMMON_CONSTANT.GROUP_NAME}:　
            </Col>
            <Col span={24}>
              <Form.Item
                name="name"
                className="rowNotMargin"
                rules={[
                  {
                    required: true,
                    message: lang.PLEASE_INPUT_NAME,
                  },
                  {
                    pattern: regExp,
                    message: lang.INPUT_FIELD_DO_NOT_SPECIAL_CHAR,
                  },
                  { validator: checkSameName },
                ]}
              >
                <Input maxLength="255" className="inputMobile" />
              </Form.Item>
            </Col>
            {typeCustom === "add" ? (
              <Col span={24}>{renderButtonLeft()}</Col>
            ) : (
              <>
                <Col span={12}>{renderButtonLeft()}</Col>
                <Col span={12}>{renderButtonRight()}</Col>
              </>
            )}
            {typeCustom !== "delete" ? (
              <Col span={24}>
                <Row justify="center">
                  <Button
                    style={{ width: "50%" }}
                    onClick={closeDialog}
                    className="button button--outline --todo--"
                  >
                    {lang.CLOSE}
                  </Button>
                </Row>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      );
    } else {
      return (
        <Row align="middle" justify="center" className="rowNotMargin">
          <Col span={24}>
            <Row justify="center" align="middle" gutter={[8, 8]}>
              <Col>{renderIcon()}</Col>
              <Col className="titleCustomGroup" style={{ paddingBottom: 0 }}>
                {title}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Paragraph
                ellipsis={{ rows: 2 }}
                style={{ color: "#A4AFC1", fontSize: "20px" }}
              >
                "{data.text}"
              </Paragraph>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col style={{ fontSize: "20px", textAlign: "center" }}>
                {COMMON_CONSTANT.WF_C033}
              </Col>
            </Row>
          </Col>
          <Col span={12}>{renderButtonLeft()}</Col>
          <Col span={12}>{renderButtonRight()}</Col>
        </Row>
      );
    }
  };
  const renderPopupConfirm = () => {
    return (
      <Row align="middle" justify="center" gutter={[8, 8]}>
        <Col span={24}>
          <Row justify="center" style={{ padding: "8px 0" }}>
            {renderIcon()}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <div style={{ fontWeight: 600, textAlign: "center" }}>
              {COMMON_CONSTANT.WF_C018}
            </div>
          </Row>
        </Col>
        <Col flex={1}>{renderButtonLeft()}</Col>

        <Col flex={1}>
          <Button className="button button--cancel" onClick={closeDialog}>
            {COMMON_CONSTANT.CLEAR}
          </Button>
        </Col>
      </Row>
    );
  };
  const redirectPage = () => {
    dispatch(setIsUpdated(false));
    history.push(redirectUrl);
    closeDialog();
  };

  const closeDialog = () => {
    formGroupName.resetFields();
    dispatch(closeModal(false));
  };

  return (
    <>
      <Modal
        className="modalMobile"
        visible={open}
        footer={null}
        centered="true"
        zIndex={10000}
        closeIcon={<></>}
        width={370}
      >
        <Row align="middle" justify="center" gutter={[8, 8]}>
          {typeCustom ? renderCustomGroup() : renderPopupConfirm()}
        </Row>
      </Modal>
    </>
  );
}
export default Confirm;
