export const COMMON_CONSTANT = {
  INPUT_FORMAT_NUM: "数字",
  INPUT_FORMAT_CHAR: "文字",
  INPUT_FORMAT_CHAR_1: "テキスト",
  INPUT_FORMAT_CHOI: "選択",
  INPUT_FORMAT_CHOI_1: "選択肢",
  INPUT_FORMAT_YESNO: "無/有",

  OTHER: "その他",
  // WF_I001: "データがありません。",

  WF_I002: "アイテムがすでに点検表に追加されています。",
  WF_I003: "機器がすでに追加されています。",
  WF_C001: "取消してもよろしいですか？", // Delete Are you sure you want to?
  WF_C002: "正常に取り込まれた。",
  WF_C003: "正常に処理が完了しました。", //"Saved successfully.",
  WF_C004: "正常に取消しました。", // "Deleted successfully.",
  WF_C005: "正常に出力されました。",
  WF_C006: "保存してもよろしいですか？", // Are you sure you want to save it?
  WF_C008: "入力内容を登録してもよろしいですか？", //Are you sure you want to register the input contents?
  WF_C009: "入力内容を更新してもよろしいですか？", //Are you sure you want to update your entry?

  WF_C018: "値が変更されています。キャンセルされます？", // Some values have changed. Are you sure you want to leave this page?

  WF_E001:
    "添付ファイルのサイズが最大値を超えています「100MB」。再度お試しください。", // Attachment size exceeds maximum '100MB'. Please try again.

  WF_E002: "「{0}」を指定してください。", // Please specify "{0}".

  WF_E004:
    "インターネットに接続されていません。接続してから再度お試しください。", //Bạn không kết nối với Internet. Vui lòng kết nối và thử lại.
  WF_E007: "タイムアウトしました。もう一度お試してください。", // Hết giờ
  WF_E008: "サーバーエラー。もう一度お試しください。", // Server error
  WF_E009: "入力したデータが既にシステムに存在している。", // The data you entered already exists in the system.
  WF_E010: "正常に失敗しました。", // Successfully failed.

  WF_E030: "入力データが無効です。再チェックしてください。", // Invalid input data. Please check again.
  WF_E031: "全ての入力必須フィールドにデータを入力していません。", // You have not entered data in all required fields.
  WF_E032: "出庫数量は在庫数量以下でなければなりません。", // Issue quantity must be less than or equal to stock quantity.
  WF_E033: "入庫数量は0より大きいでなければなりません。", // Receipt quantity must be greater than 0.
  WF_E034: "出庫数量は0より大きいでなければなりません。", // Issue quantity must be greater than 0.

  EMPTY_DATA: "データがありません。",
  EDIT_OPTION: "編集",
  VIEW_OPTION: "閲覧",
  DELETE_OPTION: "取消",

  NEW_DATA_TEMPLATE_CODE: "#{0}#HD{1}",
  SPECIAL_CHARACTER: {},

  APPROVE: "APPROVE",
  REJECT: "REJECT",
  CANCEL: "CANCEL",
  REJECTED: "REJECTED",
  CONFIRMED: "CONFIRMED",
  APPROVED: "APPROVED",
  RESOLVED: "SOLVED",
  WORKING: "WORKING",
  CONFIRMED_WORK: "CONFIRMED_WORK",
  REJECTED_WORK: "REJECTED_WORK",
  DONE: "DONE",
  COMPLETE: "COMPLETE",

  SAVE_DRAFT: "下書きとして保存",
  SAVE: "申請 ",

  CLEAR: "キャンセル",
  TITLE_DEL_GROUP: "グループ取消",
  GROUP_NAME: "グループ名",
  GROUP_TYPE: "グループ種類",

  CLOSE: "閉じる",
  ADD: "追加 ",
  UPDATE: "保存 ",
  BACK: "戻る",
  GROUP_NAME_EXISTS: "このグループ名は存在する。",
};

export const lang = {
  NAME_SYSTEM: "ワークフロー管理システム",
  UPLOAD: "アップロード",
  ADMIN_GROUP: "グループ管理者",
  GROUP_COMMON: "共通",
  GROUP_USER: "個人",
  NEWEST_VERSION: "最新バージョン",
  OFFICIAL: "正式",
  DRAFT: "下書",
  ADDED: "追加された",
  NOT_ADDED: "追加されませんでした",
  MACHINES: "機械",
  INPUT: "入力",
  FACILITY: "施設",
  BUILDING: "棟",
  FLOOR: "階",
  ROOM: "部屋",
  PROCESS: "プロセス",
  RESET: "リセット",
  ROLE_SETTING: "権限設定",

  STORAGE: "倉庫",
  IMPORTER: "入庫者",
  IMPORT_DATE: "入庫日",
  EXPORTER: "出庫者",
  EXPORT_DATE: "出庫日",
  CREATE_DATE: "作成日",
  UPDATE_DATE: "更新日",
  CREATOR: "作成者",
  UPDATER: "更新者",
  ITEM_TYPE: "項目種別",
  SAMPLES: "例",
  NUMBER: "本数",
  SHOW: "示す",
  READ_MORE: "もっと見る",

  NEW: "新規",
  DATE: "日",
  WEEK: "週",
  MONTH: "月",
  YEAR: "年",
  COLON: "：",
  TODO_CHECKLIST: "点検実施",
  BOOKMARK: "ブックマーク",

  FILTER: "フィルタ",
  CHECKLIST: "点検",
  DELETE_GROUP: "グループを取消",
  EDIT_GROUP: "グループエディター",
  ADD_TO_GROUP: "グループを追加",
  CHECKLIST_NAME: "点検書式名",
  ALL_CHECKLIST: "すべて",
  ACTION: "アクション",
  SELECT: "選択 ",
  OK: "OK",
  ERROR: "失敗",
  SUCCESS: "成功",
  WARMING: "注意",
  SETTING: "表示設定",
  ADD: "新規依頼", // Create
  ADD_CHECKLIST: "点検追加",
  CANCEL: "キャンセル",
  SEARCH: "検索 ",
  // EMPTY: "データがありません。",
  CLOSE: "閉じる",
  CLEAR: "クリアー",
  EXPORT: "ファイル出力",
  DELETE: "取消",
  DELETE_ALL: "全て取消",
  APPROVE: "承認 ",
  BULK_APPROVE: "一括承認",
  UNAPPROVE: "承認解除",
  REJECT: "否認 ",
  BULK_REJECT: "一括否認",
  ATTACH: "添付ファイル",
  ATTACH_WARNING:
    "※一申請一依頼につき依頼の内容と異なる添付ファイルは無効です。",
  BACK: "戻る",
  REASON_FOR_REJECT: "否認の理由",
  REQUIRED: "必須",
  EDIT: "編集 ",
  RE_CREATE: "再依頼",
  VERSION_UP: "バージョンアップ",
  VIEW: "閲覧",
  CLONE: "コピー新規",
  CONFIRM: "確認 ",
  APPROVED: "承認済",
  UNAPPROVED: "未承認",
  RESOLVE: "解決 ",
  LIST_VERSION: "バージョン一覧",
  VERSION: "ver.",
  SAVE: "申請",
  DISCARD: "廃棄",
  ALL: "全て",
  SEE_ALL: "全て表示",
  EXPEND_ALL: "すべて展開",
  ID: "ID",
  WORKFLOW_NUMBER: "管理番号",
  PREVIEW: "プレビュー",
  FILE_NAME: "ファイル名",
  ITEM: "項目",
  TIME: "日時",

  COMMON: "共通",
  COMPLETE: "完了",
  INCOMPLETE: "未完成",
  REGISTER: "登録",

  NOTE: "備考",
  COMMENT: "コメント",
  ADD_IMAGE: "写真を追加する",
  PREVIOUS_VALUE: "前回の値",
  RANGE: "範囲",
  REFER_VALUE_PREVIEW: "値を記入",
  VALUE: "値",
  NO: "無",
  YES: "有",
  HIGH: "高",
  MIDDLE: "中",
  LOW: "低",
  MESSAGE: "メッセージ",

  LOG_OUT: "ログアウト",
  COPYRIGHT:
    "Copyright © Sumitomo Heavy Industries Environment Co.,Ltd. All Rights Reserved.",

  APPROVER: "承認者",
  SEARCH_KEYWORD: "検索キーワード",

  CASE: "件",
  PLEASE_INPUT_NAME: "名前を入力してください",
  INPUT_FIELD_DO_NOT_SPECIAL_CHAR: "データ入力は特殊文字を許可しません",

  LOADING: "読み込み中...",
  CONNECTED: "接続済み",
  DISCONNECTED: "インターネット接続なし",
  SEND: "送信中",
  UNSENT: "未送信点検表",
  DOWNLOAD: "ダウンロード",

  REJECT_CONFIRM_TITLE: "否認してもよろしいでしょうか？",
  APPROVE_CONFIRM_TITLE: "承認してもよろしいでしょうか？",
  COMPLETE_COMFIRM_TITLE: "作業を完了してもよろしいでしょうか？",
  COMPLETE_WORK_SELECTION_COMFIRM_TITLE: "区分選択を完了してもよろしいでしょうか？",

  REJECT_CHECKLIST_SUCCESS: "否認完了しました。",
  CONFIRM_CHECKLIST_SUCCESS: "正常に確認しました。",
  APPROVE_CHECKLIST_SUCCESS: "承認完了しました。",
  SOLVED_NORMALLY: "正常に処理が完了しました。",
  REGISTER_WORK_SELECTION_SUCCESS: "区分選択が完了しました。",

  PLEASE_UPLOAD_WHEN_HAS_INTERNET:
    "インターネットが不安定なエリアのため写真のアップロードができません。インターネットが安定しているエリアで再度アップロードして下さい。",

  SEE_MORE: "もっと見る",
  TITLE: "タイトル",
  TYPE: "種類",
  CONTENT: "内容",

  REQUEST_LIST: "依頼一覧",
  APPROVE_LIST: "承認一覧",
  TASK_LIST: "作業一覧",
  DOWNLOAD_LIST: "依頼出力",
  VIEW_REQUEST: "依頼情報",

  APPROVED_DATE: "承認日",
  REJECTED_DATE: "否認日",
  RESOLVED_DATE: "完了日",

  REJECT_AND_SEND_BACK_TO_WORKER: "否認し作業者へ差戻し",
  REJECT_AND_SEND_BACK_TO_REQUESTER: "否認し依頼者へ差戻し",

  REQUESTER: "依頼者",
  REQUEST_CREATE: "新規依頼",
  REQUEST_SUBJECT: "件名",
  REQUEST_CATEGORY: "カテゴリ",
  REQUESTED_DATE: "依頼日",
  REQUEST_DUE_DATE: "依頼納期",
  REQUEST_DEPARTMENT: "依頼部門",
  REQUEST_DEPARTMENT_OF_CATEGORY: "申請先部門",
  REQUEST_CONTENT: "依頼内容",
  STATUS: "ステータス",
  CHECK_RESULT: "進行中",
  PRIORITY: "優先度",
  ADD_APPROVER: "承認者追加",
  ADD_WORKER: "作業者追加",
  ALL_CATEGORY: "全依頼",
  WORK_CLASSIFICATION: "作業区分",
  

};

export const KEY = {
  GROUP_TYPE_COMMON: "1",
  GROUP_TYPE_USER: "2",
  LAPTOP_WIDTH: "(min-device-width: 1000px)",
  NOTI: "NOTI",
  ERROR: "ERROR",
  INFO: "INFO",
  CONFIRM: "CONFIRM",
  WARMING: "WARMING",
  DISABLED: "DISABLED",
  NUMBER: "Number",
  TEXT: "Text",
  DDL: "DropDownList",
  CHECKBOX: "Checkbox",
  TEXTAREA: "TEXTAREA",
  IMPORT: 1,
  EXPORT: 2,
  SORT_TABLE: "SORT_TABLE",

  FLAG_ZERO: "0",
  FLAG_ONE: "1",

  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",

  USER_TYPE_APPROVER: "1",
  USER_TYPE_WORKER: "2",
  USER_TYPE_CONFIRM_APPROVER: "3",

  STATUS_PENDING: "1",
  STATUS_WORKING: "2",
  STATUS_CONFIRM_WORK: "3",
  STATUS_REJECT_WORK: "4",
  STATUS_DONE: "5",
  STATUS_REJECT: "6",

  ACTION_NONE: "0",
  ACTION_APPROVED: "1",
  ACTION_COMPLETE: "4",
  ACTION_CANCEL: "3",

  FLOW_STATUS_APPROVED: "1",
  FLOW_STATUS_REJECT: "2",
  FLOW_STATUS_COMPLETE: "4",

  // Setting menu
  LOC_USER_INFO: "uInfo",
  USER_ROLE: "userRole",
  MENU_SETTING: "menuSetting",
  MENU_ADMIN_OFFICE: "2", // office admin
  MENU_ADMIN: "1", // head office
  MENU_USER: "0",

  // Format date
  DATE_DEFAULT: "YYYY/MM/DD",
  WEEK: "YYYY-WW",
  MONTH: "YYYY/MM",
  YEAR: "YYYY",
  MONTH_DATE: "MM/dd",
  MONTH_DATE_MOMENT: "MM/DD",
  DATE_WITHOUT_HOUR: "yyyy/MM/dd",
  DATE_WITH_HOUR: "yyyy/MM/dd HH:mm",
  DATE_WITH_HOUR_DESKTOP: "YYYY/MM/DD HH:mm",
  HOUR_24H: "HH:mm",
};

export const STYLE = {
  BORDER_RED: "border-red",
};

export const CONFIG = {
  paging: { position: ["bottomCenter"], pageSize: 15, hideOnSinglePage: false },
};
export const LIMIT = 30;
