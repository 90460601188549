import React, { useEffect, useState } from "react";
import { Layout, Menu, Row, Col, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { collapsedSideBar } from "@src/redux/sideBar";
import { Link, useHistory } from "react-router-dom";
import ROUTER_PAGE from "@src/constants/router.const";
import { KEY, lang } from "../../constants/common.const";
import ROLES from "./../../routers/roles/roles.const";

import Logo from "@src/styles/icon/logo.svg";
import {
  FileDoneOutlined,
  ReconciliationFilled,
  UnorderedListOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";

import { useMediaQuery } from "react-responsive";
import { openConfirm } from "@src/redux/confirm";
import { updateTitleHeader } from "@src/redux/sideBar";

import "./sideBar.scss";

const { Sider } = Layout;

function SideBar() {
  const { collapsed } = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, updateCurrentPage] = useState(null);
  const { isUpdated } = useSelector((state) => state.request);
  const { roles } = useSelector((state) => state.auth);
  const isShowDownloadPage = [ROLES["1"], ROLES["2"]].includes(roles);

  const toggleButton = () => {
    dispatch(collapsedSideBar(!collapsed));
  };
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: KEY.LAPTOP_WIDTH,
  // });

  const isDesktopOrLaptop = true;

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  // dispatch(collapsedSideBar(!isPC));

  useEffect(() => {
    dispatch(collapsedSideBar(!isPC));
  }, []);

  const currentRouter = (route) => {
    if (isUpdated && route) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: route,
        })
      );
    } else {
      // Keep state after F5
      if (currentPage == null) {
        updateCurrentPage(localStorage.getItem("keyCurrent"));
      }
      let routers = ROUTER_PAGE.ROUTER_PAGE;
      let routerPath = history.location.pathname;
      if (routerPath.indexOf("/") != -1) {
        routerPath = "/" + routerPath.split("/")[1];
      }
      let keyCurrent = currentPage;

      let isUpdatedRouter = false;
      for (const [key, value] of Object.entries(routers)) {
        if (
          key !== keyCurrent &&
          [
            "REQUEST_LIST",
            "APPROVAL_LIST",
            "TASK_LIST",
            "DOWNLOAD_LIST",
          ].includes(key)
        ) {
          let isUpdated1 = false;
          value.forEach((item) => {
            if (routerPath.includes(item)) {
              isUpdated1 = true;
            }
          });
          if (isUpdated1) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        } else {
          if (value.includes(routerPath) && key !== keyCurrent) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        }
      }
      if (isUpdatedRouter) {
        dispatch(updateTitleHeader(keyCurrent));
        updateCurrentPage(keyCurrent);
        // Keep state after F5
        localStorage.setItem("keyCurrent", keyCurrent);
      }
    }
  };

  useEffect(() => {
    if (!isUpdated) {
      currentRouter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, isUpdated]);

  return isDesktopOrLaptop ? (
    <Sider
      collapsible
      collapsed={collapsed}
      width={220}
      onCollapse={toggleButton}
      className="navigation-background"
      collapsedWidth={70}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className="logo">
        <Row className="rowNotMargin" align="middle" justify="center">
          <Col style={{ marginTop: "20px" }}>
            {collapsed ? (
              <img style={{ height: "44px" }} src={Logo} alt="logo" />
            ) : (
              <img style={{ height: "60px" }} src={Logo} alt="logo" />
            )}
          </Col>
        </Row>
      </div>
      {!collapsed && (
        <Row
          justify="center"
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            paddingTop: "8px",
          }}
        >
          {lang.NAME_SYSTEM}
        </Row>
      )}
      <Menu
        mode="vertical"
        theme="dark"
        selectedKeys={[currentPage]}
        style={{ width: "100%", marginTop: collapsed ? "10px" : "20px" }}
      >
        <Menu.Item
          key="REQUEST_LIST"
          icon={<UnorderedListOutlined style={{ fontSize: "20px" }} />}
          className="customMenuItemMain"
          onClick={() => currentRouter(ROUTER_PAGE.ROUTER_PAGE.REQUEST_LIST[0])}
        >
          {!isUpdated ? (
            <Link to={ROUTER_PAGE.ROUTER_PAGE.REQUEST_LIST[0]}>
              <span>{lang.REQUEST_LIST}</span>
            </Link>
          ) : (
            <span>{lang.REQUEST_LIST}</span>
          )}
        </Menu.Item>
        <hr className="menu-bottom-divider" />

        <Menu.Item
          key="APPROVAL_LIST"
          icon={<FileDoneOutlined style={{ fontSize: "20px" }} />}
          className="customMenuItemMain"
          onClick={() =>
            currentRouter(ROUTER_PAGE.ROUTER_PAGE.APPROVAL_LIST[0])
          }
        >
          {!isUpdated ? (
            <Link to={ROUTER_PAGE.ROUTER_PAGE.APPROVAL_LIST[0]}>
              <span>{lang.APPROVE_LIST}</span>
            </Link>
          ) : (
            <span>{lang.APPROVE_LIST}</span>
          )}
        </Menu.Item>
        <hr className="menu-bottom-divider" />

        <Menu.Item
          key="TASK_LIST"
          icon={<ReconciliationFilled style={{ fontSize: "20px" }} />}
          className="customMenuItemMain"
          onClick={() => currentRouter(ROUTER_PAGE.ROUTER_PAGE.TASK_LIST[0])}
        >
          {!isUpdated ? (
            <Link to={ROUTER_PAGE.ROUTER_PAGE.TASK_LIST[0]}>
              <span>{lang.TASK_LIST}</span>
            </Link>
          ) : (
            <span>{lang.TASK_LIST}</span>
          )}
        </Menu.Item>
        <hr className="menu-bottom-divider" />

        {isShowDownloadPage && (
          <>
            <Menu.Item
              key="DOWNLOAD_LIST"
              icon={<CloudDownloadOutlined style={{ fontSize: "20px" }} />}
              className="customMenuItemMain"
              onClick={() =>
                currentRouter(ROUTER_PAGE.ROUTER_PAGE.DOWNLOAD_LIST[0])
              }
            >
              {!isUpdated ? (
                <Link to={ROUTER_PAGE.ROUTER_PAGE.DOWNLOAD_LIST[0]}>
                  <span>{lang.DOWNLOAD_LIST}</span>
                </Link>
              ) : (
                <span>{lang.DOWNLOAD_LIST}</span>
              )}
            </Menu.Item>
            <hr className="menu-bottom-divider" />
          </>
        )}

        {/* <Menu.Item
          key="ROLE_SETTING"
          icon={<SettingFilled style={{ fontSize: "20px" }} />}
          className="customMenuItemMain"
          onClick={() => currentRouter(ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING)}
        >
          <Link to={ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING}>
            <span>{lang.ROLE_SETTING}</span>
          </Link>
        </Menu.Item>
        <hr className="menu-bottom-divider" /> */}
      </Menu>
      {!collapsed && (
        <Row className="copy-right">
          <Col>{lang.COPYRIGHT}</Col>
        </Row>
      )}
    </Sider>
  ) : (
    <>
      <Drawer
        placement="left"
        className="custom-drawer"
        onClose={toggleButton}
        closable={false}
        visible={collapsed}
        key={"left"}
        footer={
          <Row className="copy-right__mobile" justify="center">
            <Col className="text-center">{lang.COPYRIGHT}</Col>
          </Row>
        }
      >
        <div className="logo">
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col
              span={20}
              style={{ marginTop: "20px" }}
              className="text-center"
            >
              <img
                style={{ height: "44px", paddingLeft: "30px" }}
                src={Logo}
                alt="logo"
              />
            </Col>
            <Col span={4}>
              <button className="hamburger opened" onClick={toggleButton}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </Col>
          </Row>
        </div>
        {!collapsed && (
          <Row
            justify="center"
            style={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              paddingTop: "8px",
            }}
          >
            {lang.NAME_SYSTEM}
          </Row>
        )}

        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[currentPage]}
          style={{ width: "100%", marginTop: collapsed ? "10px" : "20px" }}
          onClick={() => toggleButton()}
        >
          <Menu.Item
            key="REQUEST_LIST"
            icon={<UnorderedListOutlined style={{ fontSize: "20px" }} />}
            className="customMenuItemMain"
            onClick={() =>
              currentRouter(ROUTER_PAGE.ROUTER_PAGE.REQUEST_LIST[0])
            }
          >
            {" "}
            <Link to={ROUTER_PAGE.ROUTER_PAGE.REQUEST_LIST[0]}>
              <span>{lang.REQUEST_LIST}</span>
            </Link>
          </Menu.Item>
          <hr className="menu-bottom-divider" />

          <Menu.Item
            key="APPROVAL_LIST"
            icon={<FileDoneOutlined style={{ fontSize: "20px" }} />}
            className="customMenuItemMain"
            onClick={() =>
              currentRouter(ROUTER_PAGE.ROUTER_PAGE.APPROVAL_LIST[0])
            }
          >
            {" "}
            <Link to={ROUTER_PAGE.ROUTER_PAGE.APPROVAL_LIST[0]}>
              <span>{lang.APPROVE_LIST}</span>
            </Link>
          </Menu.Item>
          <hr className="menu-bottom-divider" />

          <Menu.Item
            key="TASK_LIST"
            icon={<ReconciliationFilled style={{ fontSize: "20px" }} />}
            className="customMenuItemMain"
            onClick={() => currentRouter(ROUTER_PAGE.ROUTER_PAGE.TASK_LIST[0])}
          >
            {" "}
            <Link to={ROUTER_PAGE.ROUTER_PAGE.TASK_LIST[0]}>
              <span>{lang.TASK_LIST}</span>
            </Link>
          </Menu.Item>
          <hr className="menu-bottom-divider" />

          {isShowDownloadPage && (
            <>
              <Menu.Item
                key="DOWNLOAD_LIST"
                icon={<CloudDownloadOutlined style={{ fontSize: "20px" }} />}
                className="customMenuItemMain"
                onClick={() =>
                  currentRouter(ROUTER_PAGE.ROUTER_PAGE.DOWNLOAD_LIST[0])
                }
              >
                {" "}
                <Link to={ROUTER_PAGE.ROUTER_PAGE.DOWNLOAD_LIST[0]}>
                  <span>{lang.DOWNLOAD_LIST}</span>
                </Link>
              </Menu.Item>
              <hr className="menu-bottom-divider" />
            </>
          )}

          {/* <Menu.Item
            key="ROLE_SETTING"
            icon={<SettingFilled style={{ fontSize: "20px" }} />}
            className="customMenuItemMain"
            onClick={() => currentRouter(ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING)}
          >
            <Link to={ROUTER_PAGE.ROUTER_PAGE.ROLE_SETTING}>
              <span>{lang.ROLE_SETTING}</span>
            </Link>
          </Menu.Item>
          <hr className="menu-bottom-divider" /> */}
        </Menu>
      </Drawer>
    </>
  );
}

export default SideBar;
