import Api from "@src/api";

export default {
  // 1: Search-----
  search(data) {
    return Api().get("Request/SearchRequest", data);
  },
  searchApproval(data) {
    return Api().get("Request/SearchApprovalRequest", data);
  },
  searchTask(data) {
    return Api().get("Request/SearchTaskRequest", data);
  },
  searchDownload(data) {
    return Api().get("Request/SearchDownloadRequest", data);
  },
  downloadRequest(data) {
    return Api().get("Request/DownloadRequest", {
      params: data,
      responseType:"blob"
    })
  },
  // 2: Create/Update
  save(formData, id) {
    return id
      ? Api().put(`Request/UpdateRequest/${id}`, formData, {
          // UPDATE
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      : Api().post("Request/CreateRequest", formData, {
          // CREATE
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  },
  // 3: View-----
  getDetail(id) {
    return Api().get(`Request/GetRequestById/${id}`);
  },
  changeStatus(formData, id) {
    return id
      ? Api().put(`Request/UpdateStatusRequest/${id}`, formData, {
          // UPDATE
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      : Api().post("Request/UpdateStatusRequests", formData, {
          // CREATE
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  },
  // 4: DEL-----
  delete(id) {
    return Api().delete(`Request/DeleteRequest/${id}`);
  },
};
