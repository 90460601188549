import { lang } from "@src/constants/common.const";

export const ROUTER_PAGE = {
  REQUEST_LIST: ["/RequestList"],
  APPROVAL_LIST: ["/ApprovalList"],
  TASK_LIST: ["/TaskList"],
  DOWNLOAD_LIST: ["/DownloadList"],
  ROLE_SETTING: "/RoleSetting",
};
export const NAME_PAGE = {
  REQUEST_LIST: lang.REQUEST_LIST,
  APPROVAL_LIST: lang.APPROVE_LIST,
  TASK_LIST: lang.TASK_LIST,
  DOWNLOAD_LIST: lang.DOWNLOAD_LIST,
  ROLE_SETTING: lang.ROLE_SETTING,
};
export default {
  ROUTER_PAGE: ROUTER_PAGE,
  NAME_PAGE: NAME_PAGE,
};
