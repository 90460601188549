import Api from "@src/api";

export default {
  
  // Role
  getRole(data) {
    return Api().get(`kbn/roles`);
  },

  // Request
  getRequestStatus(data) {
    return Api().get(`kbn/GetRequestStatus`);
  },
  
  getPriority(data) {
    return Api().get(`kbn/GetPriority`);
  },

  getDepartmentList() {
    return Api().get("kbn/GetDepartmentList");
  },
  getCategoryDropDownGroup(data) {
    return Api().get("kbn/GetCategoryDropDownGroup", {
      params: data,
    });
  },
  getDeparmentByCategoryDropDown() {
    return Api().get("kbn/GetDeparmentByCategoryDropDown");
  },
  getCategoryDropDown(data) {
    return Api().get("kbn/GetCategoryDropDown", {
      params: data,
    });
  },
  getWorkClassificationDropDown() {
    return Api().get("kbn/GetWorkClassificationDropDown");
  },
};
