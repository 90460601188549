/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Table,
  Space,
  Checkbox,
  Input,
  Button,
  Layout,
  Modal,
  Tooltip,
  Form,
  Divider,
} from "antd";
import FooterPage from "../../component/footer/footerPage";
import PopupAllUser from "./popupAllUser";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
// import { ReactComponent as ListIcon } from "@src/styles/icon/list.svg";
import { useMediaQuery } from "react-responsive";
import {
  COMMON_CONSTANT,
  lang,
  KEY,
  CONFIG,
  LIMIT,
} from "../../constants/common.const";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/managementRole";
import KBN from "../../api/backend/kbn";
import {
  MSG_CATCH,
  renderOption,
  statusRes,
  enterSearch,
  showMessage,
  isHeadOffice,
  isAdminOffice,
} from "../../constants/utils";

import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import {
  SearchOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  UndoOutlined,
  CloseOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import classPopup from "../../models/control/popup";

const { Content } = Layout;
const { Option } = Select;
// const objClear = { value: "", disabled: true };
let listRole = [],
  listSite = [],
  listRoleRender,
  listSiteRender;

function Role_list() {
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [initPage, setInitPage] = useState(true);
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  const [txtUser, settxtUser] = useState(new classDdl());
  const [ddlRole, setddlRole] = useState(new classText());
  const [ddlSiteOffice, setddlSiteOffice] = useState(new classDdl());
  // POPUP
  // const isPC = useMediaQuery({
  //   query: KEY.LAPTOP_WIDTH,
  // });
  const isPC = true;

  const [popupChecklist, setPopupChecklist] = useState(new classPopup());
  const [form] = Form.useForm();
  const [gridPopup, setGridPopup] = useState({
    offset: 0,
    loading: false,
    hasMore: true,
  });
  const [_listChecklist, set_listChecklist] = useState([]);
  const [_nameChecklist, set_nameChecklist] = useState(new classText());
  const [loadSpin, setLoadSpin] = useState(true);

  // Search
  const search_onClick = async (init) => {
    // Loading
    openLoadingSpinner();
    // Call
    return await API.searchUserRole({
      user: txtUser.value ? txtUser.value.trim() : null,
      role: ddlRole.value,
    })
      .then((res) => {
        if (statusRes(res)) {
          setGrid(res.data);
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        !init && showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        closeLoadingSpinner();
      });
  };

  // Column GRID
  const initColumns = [
    {
      title: "ユーザ名",
      dataIndex: "userName",
      width: "15%",
      align: "center",
    },
    {
      title: "氏名",
      dataIndex: "displayName",
      align: "center",
      // width: "15%"
    },
    {
      title: "権限設定",
      width: "50%",
      // render: (txt, row) => {
      //   const indexRow = getIndexRow(row);
      //   // List Record Role
      //   let listRowSelectRole = row.roles.map((role, indexRole) => {
      //     // DDL Role
      //     let ddl1 = renderDDLRole(role, indexRole, indexRow);
      //     // DDL Site
      //     let ddl2 = renderDDLSite(
      //       role.siteOffices.map((v) => {
      //         return v.siteOfficeMasterCode;
      //       }),
      //       role,
      //       indexRole,
      //       indexRow
      //     );

      //     return (
      //       <div key={indexRole}>
      //         <Row gutter={[5, 10]}>
      //           <Col span={6}>{ddl1}</Col>
      //           <Col span={16}>{Number(role.roleValue) !== 1 && ddl2}</Col>
      //           <Col span={2}>
      //             <div
      //               className="center mt5  pointer"
      //               onClick={() => {
      //                 onDeleteRole(row, indexRole, indexRow);
      //               }}
      //             >
      //               <CloseCircleOutlined />
      //             </div>
      //           </Col>
      //         </Row>
      //       </div>
      //     );
      //   });
      //   // Btn Add
      //   let btnAdd = () => {
      //     let domAdd = (
      //       <Row gutter={[5, 10]}>
      //         <Col span={6}>
      //           <Button
      //             className="buttonPC button--info w100"
      //             onClick={() => {
      //               onAddRole(row, getIndexRow(row));
      //             }}
      //           >
      //             <PlusOutlined className="white" />
      //           </Button>
      //         </Col>
      //       </Row>
      //     );
      //     if (
      //       (row.roles.length < 3 && // Max 3 role
      //         (row.roles.length > 0 && row.roles[0].roleValue) !== 1) || // Not Boss
      //       row.roles.length === 0 // Empty role
      //     ) {
      //       return domAdd;
      //     }
      //   };
      //   // List Row + btnAdd
      //   return (
      //     <>
      //       {listRowSelectRole}
      //       {btnAdd()}
      //     </>
      //   );
      // },
    },
    {
      title: "承認者",
      dataIndex: "isApprover",
      align: "center",
      width: "10%",
      render: (text, row) => {
        return (
          <Checkbox
            checked={text}
            onClick={(e) => {
              onApprover(row, getIndexRow(row));
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 90,
      render: (row) => (
        <Tooltip title={lang.DELETE}>
          {/* Delete */}
          <Button
            disabled={!row.canDelete}
            className="mr5 btnDelete"
            onClick={() => {
              showMessageDeleteConfirm(row.userName);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const showMessageDeleteConfirm = (userName) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();
      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        // Del
        await API.deleteUser(userName);
        // API Search
        await search_onClick(false);
        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.WF_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.WF_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  // DDL Role
  const renderDDLRole = (role, indexRole, indexRow) => {
    // debugger;
    return (
      <Select
        value={role.roleValue}
        className="w100"
        placeholder="権限"
        onChange={(e) => {
          onChangeRole(e, role, indexRow, indexRole);
        }}
      >
        {listRoleRender}
      </Select>
    );
  };

  // DDL Site Office
  const renderDDLSite = (
    lSite = ["0001", "0002"],
    role,
    indexRole,
    indexRow
  ) => {
    let ddl = (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="事業所サイト"
        value={lSite}
        onChange={(e) => {
          onChangeSite(e, role, indexRow, indexRole);
        }}
      >
        {listSiteRender}
      </Select>
    );

    return ddl;
  };

  // Change Role
  const onChangeRole = (v, role, indexRow, indexRole) => {
    let g = { ...grid };
    if (Number(v) === 1) {
      // 本社スタフ
      g.data[indexRow].roles = [
        {
          roleValue: v,
          siteOffices: [],
        },
      ];
    } else {
      // Other
      g.data[indexRow].roles[indexRole].roleValue = v;
    }
    g = checkFlagUpdate(g, indexRow);
    setGrid(g);
  };
  // Change Site
  const onChangeSite = (v = [], role, indexRow, indexRole) => {
    let g = { ...grid };
    let listSizeSelect = [];
    v.forEach((v) => {
      listSizeSelect.push({
        siteOfficeMasterCode: v,
      });
    });

    g.data[indexRow].roles[indexRole].siteOffices = listSizeSelect;
    g = checkFlagUpdate(g, indexRow);
    setGrid(g);
  };
  // Get index row
  const getIndexRow = (row) => {
    const key = row.userName;
    let indexRow;
    grid.data.forEach((u, i) => {
      if (u.userName === key) {
        indexRow = i;
      }
    });
    return indexRow;
  };
  //Add Role
  const onAddRole = (row, indexRow) => {
    let g = { ...grid };
    g.data[indexRow].roles.push({
      roleValue: null,
      siteOffices: [],
    });
    g = checkFlagUpdate(g, indexRow);
    setGrid(g);
  };
  //Delete Role
  const onDeleteRole = (row, indexRole, indexRow) => {
    let g = { ...grid };
    g.data[indexRow].roles.splice(indexRole, 1);
    g = checkFlagUpdate(g, indexRow);
    setGrid(g);
  };
  // Approver
  const onApprover = (row, indexRow) => {
    let g = { ...grid };
    g.data[indexRow].isApprover = !g.data[indexRow].isApprover;
    g = checkFlagUpdate(g, indexRow);
    setGrid(g);
  };
  // -> Flag update
  const checkFlagUpdate = (g, indexRow) => {
    g.data[indexRow].isUpdate = true;
    return g;
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Cancel
  const onCancelRole = () => {
    search_onClick();
    // setInitPage(true);
    // setGrid([]);
  };
  // SAVE
  const onSaveRole = () => {
    // Loading
    openLoadingSpinner();
    let dataSent = { ...grid };
    dataSent.data = dataSent.data.filter((v) => v.isUpdate); // Only user update role
    // Call
    return API.updateRole(dataSent.data)
      .then((res) => {
        if (statusRes(res)) {
          showMessage(KEY.INFO, COMMON_CONSTANT.WF_C003);
          search_onClick();
        } else {
          showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        setInitPage(false);
        // Close loading
        closeLoadingSpinner();
      });
  };

  // POPUP
  const popup = {
    show: () => {
      setPopupChecklist({ ...popupChecklist, show: true });
    },
    hide: () => {
      setPopupChecklist({ ...popupChecklist, show: false });
    },
    showPopup: () => {
      // Clear
      form.resetFields();
      // Re-search
      popup.search();
      popup.show();
    },
    closePopup: () => {
      // Clear
      form.resetFields();
      set_listChecklist([]);
      set_nameChecklist(new classText());
      //popup.hide();
      popup.hide();
    },
    // Search list Checklist
    search: async (reSearch) => {
      // Loading
      setLoadSpin(true);
      return API.searchUserAD({
        keyword: getKeyword(),
        offset: reSearch ? 0 : gridPopup.offset,
        limit: LIMIT,
      })
        .then((res) => {
          const data = res.data;
          set_listChecklist(popup.verifyData(data.data));
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          setLoadSpin(false);
        });
    },

    verifyData: (dataNew) => {
      return dataNew.map((v) => {
        let checked = false;
        // Checkid
        grid.data.forEach((vOld) => {
          if (vOld.userName === v.userPrincipalName) {
            checked = true;
          }
        });
        v["disabled"] = checked;
        v["checked"] = v["checked"] || checked; // Keep checked - user
        return v;
      });
    },
    addToGroup: () => {
      openLoadingSpinner();

      // List add
      const listAdd = _listChecklist.filter((v) => v.checked && !v.disabled);

      API.addUserAD(listAdd)
        .then((res) => {
          //  show message
          showMessage(
            KEY.INFO,
            <>
              {listAdd.map((v) => {
                return (
                  <>
                    {v.userPrincipalName} <br />
                  </>
                );
              })}
              {COMMON_CONSTANT.WF_C003}
            </>,
            search_onClick // Re-search home
          );
        })
        .catch((error) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .then(() => {
          // Unload
          closeLoadingSpinner();
          popup.closePopup();
        });
    },
  };

  // Update offset
  useEffect(() => {
    setGridPopup({ ...gridPopup, offset: _listChecklist.length });
  }, [_listChecklist]);
  // Search for Mobile
  useEffect(() => {
    !isPC && search_onClick();
  }, [txtUser.value]);
  // Alway device
  useEffect(() => {
    !initPage && search_onClick();
  }, [ddlSiteOffice.value, ddlRole.value]);

  const getKeyword = () => {
    return _nameChecklist.value && _nameChecklist.value.length > 0
      ? _nameChecklist.value.trim()
      : null;
  };

  const gridLoadMore = () => {
    API.searchUserAD({
      keyword: getKeyword(),
      offset: gridPopup.offset,
      limit: LIMIT,
    })
      .then((res) => {
        const data = res.data;
        // push

        set_listChecklist(popup.verifyData([..._listChecklist, ...data.data]));
      })
      .catch((error) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };
  const grid_checkbox = (id, index, checked) => {
    const list = JSON.parse(JSON.stringify(_listChecklist));
    list[index]["checked"] = checked;
    set_listChecklist(list);
  };

  // Init
  useEffect(() => {
    // Open loading
    openLoadingSpinner();

    setPopupChecklist({
      ...popupChecklist,
      show: false,
      title: lang.SEE_ALL,
    });

    // Load pulldown
    Promise.all([
      KBN.getRole(), 
    //  KBN.getSiteOffice(), 
      search_onClick(true)])
      .then((res) => {
        listRole = res[0] ? res[0].data.data : [];
        // listSite = res[1] ? res[1].data.data : [];
        // listRoleRender = renderOption(listRole, false);
    
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Set pulldown
        setddlRole({ ...ddlRole, options: listRole });
      // setddlSiteOffice({ ...ddlSiteOffice, options: listSite });
        // Close loading
        closeLoadingSpinner();
      });
  }, []);

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.ROLE_SETTING} />
      <Content
        style={{
          margin: "24px 16px",
          padding: 0,
        }}
      >
        {/* Group input */}
        <Form
          name="searchRole"
          layout={"vertical"}
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          <Row gutter={[8, isPC ? 0 : 16]}>
            <Col span={isPC ? 5 : 24}>
              <Form.Item name="txtUser" className="rowNotMargin">
                <Input
                  placeholder="ユーザ"
                  value={txtUser.value}
                  onChange={(v) => {
                    settxtUser({ ...txtUser, value: v.target.value });
                  }}
                  maxLength={255}
                  {...enterSearch(search_onClick)}
                />
              </Form.Item>
            </Col>
            {isPC && (
              <Col span="5">
                <Button
                  className="buttonPC button--info wAuto"
                  onClick={search_onClick}
                >
                  <SearchOutlined className="" />
                  {lang.SEARCH}
                </Button>
              </Col>
            )}
          </Row>
          {isPC && <Divider className="ml0 mr0 mt20 mb20" />}
          <Row gutter={[8, isPC ? 0 : 8]} className="align-center">
            {isPC && (
              <Col className="bold mr10">
                {lang.FILTER}
                {lang.COLON}
              </Col>
            )}
            <Col span={isPC ? 5 : 12}>
              <Form.Item name="ddlRole" className="rowNotMargin">
                <Select
                  placeholder="権限"
                  value={ddlRole.value}
                  // className="w100 ant-input selectHtml "
                  onChange={(v) => {
                    setddlRole({ ...ddlRole, value: v });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlRole.options)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={isPC ? 5 : 12}>
              {/* 2 auto picker */}
              <Form.Item name="ddlSiteOffice" className="rowNotMargin">
                <Select
                  placeholder="事業所サイト"
                  value={ddlSiteOffice.value}
                  // className="w100 ant-input selectHtml "
                  onChange={(v) => {
                    setddlSiteOffice({
                      ...ddlSiteOffice,
                      value: v,
                    });
                  }}
                  {...enterSearch(search_onClick)}
                >
                  {renderOption(ddlSiteOffice.options)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>

      {!initPage && (
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          {/* <div className="titleText2 ">
            <ListIcon className="w18px svg-default mr5" /> {lang.ROLE_SETTING}
          </div> */}
          <Row justify="space-between mb15">
            <Col span={12}>
              {/* Add */}
              {(isHeadOffice() || isAdminOffice()) && (
                <Button
                  type="link"
                  className="pl0 pr0 m-wAuto btnLink"
                  onClick={() => {
                    popup.showPopup();
                  }}
                >
                  <AddIcon className="svg-default mr5" />
                  <span style={{ fontWeight: 600 }}>{COMMON_CONSTANT.ADD}</span>
                </Button>
              )}
            </Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                該当件数 <span>{grid.count}件</span>
              </Space>
            </Col>
          </Row>

          {/* Grid  */}
          <Table
            rowKey={(record) => record.userName}
            className="gridSearch tblRole mt10"
            columns={initColumns}
            dataSource={grid.data}
            pagination={CONFIG.paging}
          />
        </Content>
      )}

      {/* Footer */}
      {grid.data.length > 0 && (
        <FooterPage>
          <Row justify="">
            <Col>
              <Button
                className="buttonPC button--info wAuto  mb5 mt5"
                onClick={onSaveRole}
              >
                <SaveOutlined />
                {lang.SAVE}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10 mb5 mt5"
                onClick={onCancelRole}
              >
                <UndoOutlined />
                {lang.RESET}
              </Button>
            </Col>
          </Row>
        </FooterPage>
      )}

      <Modal
        className={"popupP2"}
        centered
        title={popupChecklist.title}
        visible={popupChecklist.show}
        // onOk={handleOk}
        onCancel={popup.closePopup}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={popup.addToGroup}>
            <PlusOutlined />
            {COMMON_CONSTANT.ADD}
          </Button>,
          <Button key="back" onClick={popup.closePopup}>
            <CloseOutlined />
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <PopupAllUser
          isPC={isPC}
          loadSpin={loadSpin}
          popup={popup}
          _nameChecklist={_nameChecklist}
          set_nameChecklist={set_nameChecklist}
          gridPopup={gridPopup}
          _listChecklist={_listChecklist}
          form={form}
          gridLoadMore={gridLoadMore}
          grid_checkbox={grid_checkbox}
        />
      </Modal>

      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default Role_list;
