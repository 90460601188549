import React, { useState } from "react";
import { Col, Row, Layout } from "antd";
import { DownSquareOutlined, UpSquareOutlined } from "@ant-design/icons";

const { Content } = Layout;

function GroupItem({
  name,
  sub,
  children,
  center = false,
  expand = false,
  initExpand = true,
  ...other
}) {
  let [show, setshow] = useState(initExpand);
  const onExpand = () => {
    expand && setshow(!show);
  };

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: "24px 16px",
        padding: 24,
      }}
    >
      <div className="group-item">
        {name && (
          <div className="title-group mb15">
            <h3
              onClick={onExpand}
              // className="pointer"
            >
              <Row justify="space-between">
                <Col>{name}</Col>
                <Col>
                  {expand && (
                    <span className="expand-icon nonSelect orange">
                      {show ? <UpSquareOutlined /> : <DownSquareOutlined />}
                    </span>
                  )}
                </Col>
              </Row>
            </h3>
            {sub && show ? (
              <div className="sub-title-group">{"※" + sub}</div>
            ) : (
              <div className="mb20"></div>
            )}
          </div>
        )}
        <div className={show ? "" : "d-none"}>
          {center ? (
            <Row>
              <Col span={2}></Col>
              <Col span={20}>{children}</Col>
              <Col span={2}></Col>
            </Row>
          ) : (
            children
          )}
        </div>
      </div>
    </Content>
  );
}

export default GroupItem;
