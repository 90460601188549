import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import loadable from "@loadable/component";
// Side Bar
import SideBar from "@src/component/SideBar/sideBar";
import { useMediaQuery } from "react-responsive";
import PrivateRouter from "@src/routers/privateRouter";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { openConfirm } from "@src/redux/confirm";
import { KEY } from "../constants/common.const";
import { updateCurrentRouter } from "@src/redux/router";

const Headers = loadable(() => import("@src/component/Header/header"));

function Router({ history }) {
  const dispatch = useDispatch();
  const [currentRouter, setCurrentRouter] = React.useState("/");
  const { collapsed } = useSelector((state) => state.sideBar);

  const stateCL = useSelector((state) => state.checklist);
  const [showPopupSave, setShowPopup] = useState(false);
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: KEY.LAPTOP_WIDTH,
  // });
  const isDesktopOrLaptop = true;
  history.listen((location) => {
    setCurrentRouter(location.pathname);
  });

  React.useEffect(() => {
    if (currentRouter == "/") {
      dispatch(updateCurrentRouter(history.location.pathname));
    } else {
      dispatch(updateCurrentRouter(currentRouter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouter, dispatch]);

  const routerContent = useMemo(() => {
    return (
      <div className={isDesktopOrLaptop ? "des" : "des des--white"}>
        <Headers />
        <PrivateRouter />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopOrLaptop]);
  return (
    <Layout>
      <SideBar />
      <Layout
        className="site-layout"
        style={
          isDesktopOrLaptop
            ? collapsed
              ? { paddingLeft: "64px" }
              : { paddingLeft: "220px" }
            : {}
        }
      >
        {routerContent}
      </Layout>
    </Layout>
  );
}
export default withRouter(Router);
