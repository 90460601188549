import React, { useState, useEffect } from "react";
import {
  Input,
  Col,
  Row,
  Table,
  Space,
  Button,
  DatePicker,
  Form,
  Layout,
  Select,
  Typography,
} from "antd";

import { KEY, lang, CONFIG, STYLE } from "../../constants/common.const";

import STATUS from "@src/constants/status.const";
import { useHistory } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import moment from "moment";
import FooterPage from "../../component/footer/footerPage";
import { Link } from "react-router-dom";
import API from "../../api/backend/request";
import KBN from "../../api/backend/kbn";

import {
  saveFile,
  MSG_CATCH,
  INPUT_DATE,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  getNumberValue,
  renderOption,
} from "../../constants/utils";

import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import {
  CloudDownloadOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";

const { Paragraph } = Typography;
const { Content } = Layout;
const objClear = { value: null, label: null, disabled: true, checked: false };

let objSearchOrigin = {
  subject: null,
  category: null,
  startDate: null,
  endDate: null,
  status: null,
  keyWord: null,
};

let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function Request_List({ history: historyPage }) {
  const [currentPage, setCurrentPage] = useState(1);

  // Step1: Update param
  if (historyPage.action === "POP") {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }

  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  // 1
  const [txtRequestId, setTxtRequestId] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.id),
  });

  const [txtSubject, setTxtSubject] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.subject),
  });

  const [txtCreateStartDate, setTxtCreateStartDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch.startDate),
  });
  const [txtCreateEndDate, setTxtCreateEndDate] = useState({
    ...new classText(),
    value: getDateValue(objSearch.endDate),
  });

  const [txtKeyword, setTxtKeyword] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.keyWord),
  });

  let [ddlStatus, setddlStatus] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.status),
  });

  let [ddlCategory, setDdlCategory] = useState({
    ...new classDdl(),
    value: getNumberValue(objSearch.category),
  });

  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.subject = txtSubject.value ? txtSubject.value.trim() : null;
    objSearch.category = ddlCategory.value ? ddlCategory.value : null;
    objSearch.status = ddlStatus.value ? ddlStatus.value : null;
    objSearch.startDate = txtCreateStartDate.value;
    objSearch.endDate = txtCreateEndDate.value;
    objSearch.keyWord = txtKeyword.value ? txtKeyword.value.trim() : null;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = () => {
    // Loading
    openLoadingSpinner();

    // input to objectSearch; push url
    syncInput();

    // Update format
    objSearch = getQuerySearch();
    objSearch.startDate = int2DateString(Number(objSearch.startDate));
    objSearch.endDate = int2DateString(Number(objSearch.endDate));

    // Call
    return API.searchDownload({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        setGrid({ ...grid, data: res.data, count: res.data.length });
        setCurrentPage(1);
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };

  // clearInputSearch
  const clearInputSearch = () => {
    setTxtCreateStartDate({
      ...txtCreateStartDate,
      value: getDateValue(moment(new Date())),
    });
    setTxtCreateEndDate({
      ...txtCreateEndDate,
      value: getDateValue(moment(new Date())),
    });

    setTimeout(() => {
      setTxtRequestId(objClear);
      setTxtSubject(objClear);
      setTxtCreateStartDate(objClear);
      setTxtCreateEndDate(objClear);
      setTxtKeyword(objClear);
      setddlStatus({
        ...ddlStatus,
        value: null,
        label: null,
      });
      setDdlCategory({
        ...ddlCategory,
        value: null,
        label: null,
      });
      formSearch.resetFields();
      formSearch.submit();
    }, 1);
  };

  // Column GRID
  const initColumns = [
    {
      title: lang.WORKFLOW_NUMBER,
      dataIndex: "id",
      width: 110,
      align: "center",
      fixed: "left",
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              <Link to={"/ViewRequest/" + row.id} className="white-space">
                {text}
              </Link>
            </Col>
          </Row>
        );
      },
    },
    {
      title: lang.REQUEST_SUBJECT,
      dataIndex: "title",
      width: 250,
    },
    {
      title: lang.REQUEST_CATEGORY,
      dataIndex: "categoryName",
      width: 250,
    },
    {
      title: lang.REQUESTER,
      dataIndex: "displayName",
      width: 150,
      align: "center",
    },
    {
      title: lang.REQUESTED_DATE,
      dataIndex: "requestedDateDisplay",
      width: 100,
      align: "center",
    },
    {
      title: lang.REQUEST_DUE_DATE,
      dataIndex: "expectedCompleteDateDisplay",
      align: "center",
      width: 100,
    },
    {
      title: lang.RESOLVED_DATE,
      dataIndex: "completeDateDisplay",
      width: 100,
      align: "center",
    },
    {
      title: lang.REQUEST_DEPARTMENT,
      dataIndex: "departmentName",
      align: "center",
      width: 250,
    },
    {
      title: lang.STATUS,
      dataIndex: "statusValue",
      align: "center",
      width: 80,
      fixed: "right",
      render: (text, row) => {
        return (
          <Paragraph
            ellipsis={{ rows: 1 }}
            className="textStatus"
            style={{
              background: STATUS.STATUS_COLOR[row.status],
              // background: STATUS.STATUS_COLOR[row.status] + "1A",
              // border: "1px solid " + STATUS.STATUS_COLOR[row.status]
              fontWeight: "bold",
            }}
          >
            {text}
          </Paragraph>
        );
      },
    },
  ];

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // Init
  useEffect(() => {
    // SEARCH
    // search_onClick();

    //  getKbnRequestStatus();

    openLoadingSpinner();

    Promise.all([KBN.getRequestStatus(), KBN.getCategoryDropDown()])
      .then((res) => {
        let requestStatus = res[0] ? res[0].data.data : [];
        setddlStatus({ ...ddlStatus, options: requestStatus });

        let categories = res[1] ? res[1].data.data : [];
        let label = null;
        let selectedCate = categories.filter(
          (cate) => cate.key == ddlCategory.value
        );
        if (selectedCate && selectedCate.length > 0) {
          label = selectedCate[0].value;
        }
        setDdlCategory({
          ...ddlCategory,
          options: [{ key: "", value: "" }, ...categories],
          label: label,
        });
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downLoadRequest = async () => {
    try {
      setShowLoading(true);

      // Update format
      objSearch = getQuerySearch();
      objSearch.startDate = int2DateString(Number(objSearch.startDate));
      objSearch.endDate = int2DateString(Number(objSearch.endDate));

      const res = await API.downloadRequest(objSearch);
      saveFile(res);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.DOWNLOAD_LIST} />
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
          <Col span={6}>
              <div className="ant-form-item-label">{lang.REQUEST_SUBJECT}</div>
              <Input
                placeholder={lang.REQUEST_SUBJECT}
                value={txtSubject.value}
                maxLength={255}
                onChange={(e) =>
                  setTxtSubject({
                    ...txtSubject,
                    value: e.target.value,
                  })
                }
              />
            </Col>
            <Col span={6}>
              <div className="ant-form-item-label">{lang.REQUEST_CATEGORY}</div>
              <Select
                showSearch
                value={ddlCategory.label}
                className={
                  ddlCategory.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  setDdlCategory({
                    ...ddlCategory,
                    value: option.key,
                    label: value,
                  });
                }}
                options={ddlCategory.options}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.trim().toUpperCase()) !== -1
                }
              >
                {renderOption(ddlCategory.options)}
              </Select>
            </Col>
            <Col span={6}>
              <div className="ant-form-item-label">{lang.STATUS}</div>
              <Select
                value={ddlStatus.value}
                className={
                  ddlStatus.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(v) => {
                  setddlStatus({ ...ddlStatus, value: v });
                }}
              >
                {renderOption(ddlStatus.options)}
              </Select>
            </Col>
            <Col span={6}></Col>
            <Col span={6}>
              <div className="ant-form-item-label">{lang.REQUESTED_DATE}</div>
              <Row>
                <Col span={10}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtCreateStartDate.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtCreateStartDate({
                        ...txtCreateStartDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={10}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtCreateEndDate.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtCreateEndDate({
                        ...txtCreateEndDate,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>

            <Col flex="auto"></Col>
            {/* Button Search/Clear */}
            <Col flex="none" className="right">
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          // rowSelection={{
          //   selectedRowKeys: selectedData.map((v) => v.id),
          //   onChange: onSelectRequest,
          //   getCheckboxProps: (record) => ({
          //     disabled: record.needAction != "1",
          //   }),
          // }}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            current: currentPage,
            onChange: (curr) => setCurrentPage(curr),
          }}
          scroll={{ x: 1800 }}
        />
      </Content>
      <Loading show={showLoading}></Loading>

      <FooterPage>
        <Row justify="end">
          <Col>
            <Button
              disabled={grid.data.length == 0}
              className=" button--outline wAuto mr15 mb5 mt5"
              style={{ border: "none !important" }}
              onClick={() => {
                downLoadRequest();
              }}
            >
              <CloudDownloadOutlined className="orange" />
              {lang.DOWNLOAD_LIST}
            </Button>
          </Col>
        </Row>
      </FooterPage>
    </div>
  );
}
export default Request_List;
