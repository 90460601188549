import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentUser: null,
  currentRouter: "/",
  changeRoute: null,
};
const routerSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    updateCurrentRouter: (state, { payload }) => {
      state.currentRouter = payload;
    },
    updateCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    updateAndChangeCurrentRoute: (state, { payload }) => {
      state.changeRoute = payload;
    },
  },
});
// the action in redux ~ the commit in vuex
export const {
  updateAndChangeCurrentRoute,
  updateCurrentUser,
  updateCurrentRouter,
} = routerSlice.actions;

// the selector
export const routerSelector = (state) => state.router;

// the reducer ~ the mutation in vuex
export default routerSlice.reducer;
