import React from "react";
import { useSelector } from "react-redux";
import configRoles from "./roles/configRoles";
import { Route, Switch, Redirect } from "react-router-dom";
import E404 from "@src/page/Error/404";
import { useMediaQuery } from "react-responsive";
import { getAllowedRoutes } from "@src/utils/roles";
import { KEY } from "../constants/common.const";
function PrivateRouter() {
  
  const { roles } = useSelector((state) => state.auth);
  const [allowedRoutes, updateAllowedRoutes] = React.useState([]);
  React.useEffect(() => {
    const arrayRoutes = getAllowedRoutes(configRoles, roles);
    const array = [];
    arrayRoutes.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => array.push(child));
      } else {
        array.push(item);
      }
    });
    updateAllowedRoutes(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/RequestList" />
      </Route>
      {allowedRoutes.map((route) => {
        const { exact, path, component } = route;
        return (
          <Route
            exact={exact}
            component={component}
            key={path}
            path={path}
          ></Route>
        );
      })}
      {/* Error */}
      <Route exact path="/404" component={E404}></Route>
    </Switch>
  );
}

export default PrivateRouter;
