import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isUpdated: false,
};
const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setIsUpdated: (state, { payload }) => {
      state.isUpdated = payload;
    },
  },
});
// the action in redux ~ the commit in vuex
export const { setIsUpdated } = requestSlice.actions;

// the selector
export const requestSelector = (state) => state.request;

// the reducer ~ the mutation in vuex
export default requestSlice.reducer;
