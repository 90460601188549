import React, { useEffect } from "react";
import "./App.scss";
import "./styles/css/common.scss";
import "./styles/css/mobile.scss";
import "./styles/css/vars.scss";
import Router from "@src/routers/router";
import { Layout } from "antd";
import Loading from "@src/component/Popup/loading";
import Confirm from "@src/component/Popup/confirm";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusNetWork, getUserInfo } from "@src/redux/auth";
import { useHistory } from "react-router-dom";
const { Content } = Layout;
// reset item page to select menu when reload page or destroy app
window.onbeforeunload = function () {
  localStorage.removeItem("page");
};

function App() {
 const { currentUser } = useSelector((state) => state.router);
  const history = useHistory();
  const dispatch = useDispatch();
  const onOffline = () => {
    dispatch(changeStatusNetWork(false));
  };
  const onOnline = () => {
    dispatch(changeStatusNetWork(true));
  };
  useEffect(() => {
    dispatch(getUserInfo(currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  let vh = window.innerHeight * 0.01;
  // set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout className="layout" id="layout">
      <Content>
        <Router />
      </Content>
      <Loading />
      <Confirm />
    </Layout>
  );
}
export default App;
