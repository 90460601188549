/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Typography, Input, Button } from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import UserPanel from "../../component/Desktop/userPanel";
import FooterPage from "../../component/footer/footerPage";

import RequestFlow from "../../component/Flow/requestFlow";
import ConfirmModal from "../../component/Popup/confirm-modal";

import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import API from "../../api/backend/request";
import KBN from "../../api/backend/kbn";
import BreakLine from "../../component/Desktop/breakLine";
import { MSG_CATCH, showMessage, isEmpty } from "../../constants/utils";
import { useHistory, useParams } from "react-router-dom";
import Constants from "@src/constants/status.const";
import {
  CopyOutlined,
  RedoOutlined,
  SaveOutlined,
  EditFilled,
  RollbackOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import STATUS from "@src/constants/status.const";
import classPopup from "../../models/control/popup";
import { format } from "react-string-format";

const { Paragraph } = Typography;

function ViewRequest() {
  let { id } = useParams();
  const history = useHistory();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  let [requestFlow, setRequestFlow] = useState([]);
  const [action, setAction] = useState(undefined);
  const { isAuth, isAdmin, username } = useSelector((state) => state.auth);

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  let [txtId, settxtId] = useState(new classText());
  let [txtTitle, settxtTitle] = useState(new classText());
  let [txtCategory, settxtCategory] = useState(new classText());
  let [txtCategoryType, settxtCategoryType] = useState(new classText());
  let [txtStatus, settxtStatus] = useState(new classText());
  let [txtStep, settxtStep] = useState(new classText());
  let [txtStatusText, settxtStatusText] = useState(new classText());
  let [txtNeedAction, settxtNeedAction] = useState(new classText());
  let [txtPriority, settxtPriority] = useState(new classText());
  let [txtDepartment, settxtDepartment] = useState(new classText());
  let [txtContent, settxtContent] = useState(new classText());
  let [txtRequester, settxtRequester] = useState(new classText());
  let [txtCreateDateDisplay, settxtCreateDateDisplay] = useState(
    new classText()
  );
  let [txtExpectedCompleteDateDisplay, settxtExpectedCompleteDateDisplay] =
    useState(new classText());
  let [txtCompleteDateDisplay, settxtCompleteDateDisplay] = useState(
    new classText()
  );

  let [txtWorkClassification, setTxtWorkClassification] = useState(
    new classText()
  );
  let [txtWorkClassificationCode, setTxtWorkClassificationCode] = useState(
    new classText()
  );
  let [useWorkClassification, setUseWorkClassification] = useState(false);
  let [ddlWorkClassification, setddlWorkClassification] = useState(
    new classDdl()
  );

  // File
  let [tblAttach, setTblAttach] = useState([]);
  let [popupConfirm, setPopupConfirm] = useState(new classPopup());
  let [errorMessage, setErrorMessage] = useState("");

  let [allowEditUser, setAllowEditUser] = useState(false);

  useEffect(() => {
    if (action === undefined) return;
    switch (action.action) {
      case KEY.UPDATE:
        const newRequestFlow = requestFlow.map((item, i) => {
          if (i === action.index) {
            return action.data;
          } else {
            return item;
          }
        });
        setRequestFlow(newRequestFlow);
        break;

      default:
        setAction(undefined);
        break;
    }
  }, [action]);

  const init = () => {
    if (!isAuth) {
      return;
    }

    setInitPage(true);

    // Open loading
    openLoadingSpinner();

    Promise.all([API.getDetail(id), KBN.getWorkClassificationDropDown()])
      .then((res) => {
        let data = res[0] ? res[0].data : null;
        let wC = res[1] ? res[1].data.data : [];

        //Group1
        if (!data || !wC) {
          throw new Error();
        }

        // Normal
        settxtId({ ...txtId, value: data.id });
        settxtTitle({ ...txtTitle, value: data.title });
        settxtCategory({ ...txtCategory, value: data.categoryName });
        settxtCategoryType({ ...txtCategoryType, value: data.categoryType });
        settxtDepartment({ ...txtDepartment, value: data.departmentName });
        settxtRequester({ ...txtRequester, value: data.displayName });
        settxtStatus({ ...txtStatus, value: data.status });
        settxtStatusText({ ...txtStatusText, value: data.statusValue });
        settxtStep({ ...txtStep, value: data.step });
        settxtPriority({ ...txtStatus, value: data.priorityValue });
        settxtContent({ ...txtContent, value: data.description });
        settxtNeedAction({ ...txtNeedAction, value: data.needAction });
        settxtCreateDateDisplay({
          ...txtCreateDateDisplay,
          value: data.requestedDateDisplay,
        });
        settxtExpectedCompleteDateDisplay({
          ...txtExpectedCompleteDateDisplay,
          value: data.expectedCompleteDateDisplay,
        });
        settxtCompleteDateDisplay({
          ...txtCompleteDateDisplay,
          value: data.completeDateDisplay,
        });

        if (data.useWorkClassification == 1) {
          setUseWorkClassification(true);
        }

        setddlWorkClassification({
          ...ddlWorkClassification,
          options: wC,
          label: data.workClassificationName,
          value: data.workClassificationCode,
        });
        
        setTxtWorkClassification({
          ...txtWorkClassification,
          value: data.workClassificationName,
        });

        //Attachment
        setTblAttach(data.listRequestAttachment);

        // Request flow
        let indexType = {};
        data.listRequestFlow = data.listRequestFlow.map((v) => {
          const userType = v.userType;
          indexType[userType] = indexType[userType]
            ? indexType[userType] + 1
            : 1;
          let row = {};
          row["StepOrder"] = v.stepOrder;
          row["CurrentStep"] = data.step;

          let userLabel = v.userLabel;
          let userTypeIndex =
            STATUS.FLOW_USER_TYPE[userType] + indexType[userType];

          row["UserLabel"] = userLabel;
          row["UserType"] = v.userType;
          row["UserTypeIndex"] = userTypeIndex;
          row["UserName"] = v.userName;
          row["UserNameDisplay"] = v.userNameDisplay;
          row["Remark"] = v.remark;
          row["Status"] = v.status;
          row["UpdateDatetimeDisplay"] = v.updateDatetimeDisplay;
          row["ListFlowAttachment"] = v.listFlowAttachment;
          row["Element"] = { ...new classText(), users: [v.userName] };

          let colorStatus = v.status;
          if (v.stepOrder === data.step) {
            colorStatus = "5";
          }
          const colorIndex = Constants.STATUS_LIST.findIndex(
            (item) => item.key === colorStatus
          );
          if (colorIndex !== -1) {
            const colorCode = Constants.STATUS_LIST[colorIndex]["color"];
            row["PanelColor"] = colorCode;
          }

          if (v.stepOrder === data.step + 1) {
            if (v.userName === "") {
              setErrorMessage(
                "※" +
                  format(
                    COMMON_CONSTANT.WF_E002,
                    userTypeIndex + "(" + userLabel + ")"
                  )
              );
            }
          }

          if (v.stepOrder === data.step - 1) {
            if (v.userName === username) {
              setAllowEditUser(true);
            }
          }

          return row;
        });
        setRequestFlow(data.listRequestFlow);
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setTimeout(() => {
          setInitPage(false);
          closeLoadingSpinner();
        }, 500); // waiting for setup
      });
  };

  // Init
  useEffect(init, [id, isAuth]);

  useEffect(() => {});

  function backToSearch() {
    history.goBack();
  }

  const handleClose = () => {
    setPopupConfirm({
      ...popupConfirm,
      show: false,
    });
  };

  function showPopupConfirm(id, mode) {
    mode = String(mode);
    let customTitle = false;
    let title;
    let type;

    let currentFlow = requestFlow.filter(
      (item) => item.StepOrder == txtStep.value
    )[0];

    let nextWorkerFlow;
    if (currentFlow && currentFlow.UserType != KEY.USER_TYPE_WORKER) {
      nextWorkerFlow = requestFlow.filter(
        (item) => item.UserType == KEY.USER_TYPE_WORKER && item.UserName == ""
      )[0];
      if (nextWorkerFlow) {
        nextWorkerFlow = {
          ...nextWorkerFlow,
          Required: nextWorkerFlow.StepOrder == txtStep.value + 1,
        };
      }
    }

    if (mode === COMMON_CONSTANT.REJECT) {
      // Reject mode
      customTitle = true;
      title = lang.REJECT_CONFIRM_TITLE;
      type = COMMON_CONSTANT.REJECT;
    } else if (mode === COMMON_CONSTANT.APPROVE) {
      // Approve mode
      customTitle = true;
      title = lang.APPROVE_CONFIRM_TITLE;
      type = COMMON_CONSTANT.APPROVE;
    } else if (mode === COMMON_CONSTANT.COMPLETE) {
      customTitle = true;

      if (useWorkClassification) {
        title = lang.COMPLETE_WORK_SELECTION_COMFIRM_TITLE;
      } else {
        title = lang.COMPLETE_COMFIRM_TITLE;
      }

      type = COMMON_CONSTANT.COMPLETE;
    } else {
      // other
    }

    // Show popup
    setPopupConfirm({
      ...popupConfirm,
      show: true,
      id: id,
      title: title,
      customTitle: customTitle,
      type: type,
      flowObject: nextWorkerFlow,
      requestStatus: txtStatus.value,
    });
    return false;
  }

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.VIEW_REQUEST} />

      {/* Group 1 */}
      <GroupItem name={lang.VIEW_REQUEST} center>
        {!isEmpty(errorMessage) && (
          <>
            <div className="red fs14 f-bold mb10">{errorMessage}</div>
          </>
        )}

        <div className="mt15"></div>
        <BreakLine />
        <RowInput name={lang.WORKFLOW_NUMBER} box>
          <div className="white-space"> {txtId.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUEST_SUBJECT} box>
          <div className="white-space"> {txtTitle.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUEST_DEPARTMENT_OF_CATEGORY} box>
          <div className="white-space"> {txtCategoryType.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUEST_CATEGORY} box>
          <div className="white-space"> {txtCategory.value}</div>
        </RowInput>
        <BreakLine />

        {!isEmpty(txtWorkClassification.value) && (
          <>
            <RowInput name={lang.WORK_CLASSIFICATION} box>
              <div className="white-space"> {txtWorkClassification.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}

        <RowInput name={lang.STATUS} box>
          {/* <div className="white-space">{txtStatus.value}
          
          </div> */}

          <Paragraph
            ellipsis={{ rows: 1 }}
            className="textStatus"
            style={{
              width: "min-content",
              background: STATUS.STATUS_COLOR[txtStatus.value],
              // background: STATUS.STATUS_COLOR[row.status] + "1A",
              // border: "1px solid " + STATUS.STATUS_COLOR[row.status]
              fontWeight: "bold",
            }}
          >
            {txtStatusText.value}
          </Paragraph>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUEST_DEPARTMENT} box>
          <div className="white-space">{txtDepartment.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUESTER} box>
          <div className="white-space">{txtRequester.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUEST_CONTENT} box>
          <div className="white-space">{txtContent.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUESTED_DATE} box>
          <div className="white-space">{txtCreateDateDisplay.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.REQUEST_DUE_DATE} box>
          <div className="white-space">
            {txtExpectedCompleteDateDisplay.value}
          </div>
        </RowInput>
        <BreakLine />
        {!isEmpty(txtCompleteDateDisplay.value) && (
          <>
            <RowInput name={lang.RESOLVED_DATE} box>
              <div className="white-space">{txtCompleteDateDisplay.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}
        <RowInput name={lang.PRIORITY} box>
          <div className="white-space">{txtPriority.value}</div>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.ATTACH} box>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {tblAttach.map((item, index) => {
              return (
                <li key={index} className="machine-file">
                  <a href={item.attachmentUrl} target="_blank">
                    {item.fileName}
                  </a>
                </li>
              );
            })}
          </ul>
        </RowInput>
        <BreakLine />
      </GroupItem>

      <GroupItem name="依頼フロー" center>
        <Row>
          <Col span={8}>
            {!initPage && (
              <>
                <RequestFlow
                  requester={txtRequester.value}
                  flowObject={requestFlow}
                />
              </>
            )}
          </Col>
          <Col
            span={16}
            className={"pl20"}
            style={{ borderLeft: "1px solid #e6e6e6" }}
          >
            <Row>
              {!initPage &&
                requestFlow.length > 0 &&
                requestFlow.map((item, index) => {
                  return (
                    <UserPanel
                      key={index}
                      viewMode
                      getUser={item.Element}
                      setUser={(data) => {
                        item["Element"] = data;
                        item["UserName"] =
                          data.users.length > 0 ? data.users[0] : "";
                        setAction({
                          action: KEY.UPDATE,
                          index: index,
                          data: item,
                        });
                      }}
                      flowObject={item}
                    />
                  );
                })}
            </Row>
          </Col>
        </Row>
      </GroupItem>

      <FooterPage>
        <Row justify="end">
          <Col>
            <Button
              className="buttonPC button--outline wAuto ml15 mb5 mt5"
              onClick={() => {
                history.push("/CloneRequest/" + id);
              }}
            >
              <CopyOutlined />
              {lang.CLONE}
            </Button>

            {txtStatus.value === KEY.STATUS_REJECT && (
              <>
                <Button
                  className="buttonPC button--info wAuto ml15 mb5 mt5"
                  onClick={() => {
                    history.push("/EditRequest/" + id);
                  }}
                >
                  <RedoOutlined />
                  {lang.RE_CREATE}
                </Button>
              </>
            )}

            {txtNeedAction.value === KEY.FLAG_ONE &&
              (txtStatus.value === KEY.STATUS_WORKING ||
                txtStatus.value === KEY.STATUS_REJECT_WORK) && (
                <>
                  <Button
                    className="buttonPC button--info wAuto ml15 mb5 mt5"
                    onClick={() => {
                      showPopupConfirm(id, COMMON_CONSTANT.COMPLETE);
                    }}
                  >
                    <SaveOutlined />{" "}
                    {useWorkClassification ? (
                      <>{lang.REGISTER}</>
                    ) : (
                      <>{lang.COMPLETE}</>
                    )}
                  </Button>
                </>
              )}

            {(allowEditUser ||
              Number(isAdmin) === 1 ||
              (txtNeedAction.value === KEY.FLAG_ONE &&
                (txtStatus.value === KEY.STATUS_PENDING ||
                  txtStatus.value === KEY.STATUS_CONFIRM_WORK))) && (
              <>
                <Button
                  className="buttonPC button--outline wAuto ml15 mb5 mt5"
                  onClick={() => {
                    history.push("/EditRequestFlow/" + id);
                  }}
                >
                  <EditFilled />
                  {lang.EDIT}
                </Button>
              </>
            )}

            {txtNeedAction.value === KEY.FLAG_ONE &&
              (txtStatus.value === KEY.STATUS_PENDING ||
                txtStatus.value === KEY.STATUS_CONFIRM_WORK) && (
                <>
                  <Button
                    className="buttonPC button--info wAuto ml15 mb5 mt5"
                    onClick={() => {
                      showPopupConfirm(id, COMMON_CONSTANT.APPROVE);
                    }}
                  >
                    <CheckCircleOutlined />
                    {lang.APPROVE}
                  </Button>
                  <Button
                    className="buttonPC button--info wAuto ml15 mb5 mt5"
                    onClick={() => {
                      showPopupConfirm(id, COMMON_CONSTANT.REJECT);
                    }}
                  >
                    <MinusCircleOutlined />
                    {lang.REJECT}
                  </Button>
                </>
              )}
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
              onClick={() => {
                backToSearch();
              }}
            >
              <RollbackOutlined />
              {lang.BACK}
            </Button>
          </Col>
        </Row>
      </FooterPage>

      {/* Popup confirm */}
      <ConfirmModal
        popupConfirm={popupConfirm}
        useWorkClassification={useWorkClassification}
        ddlWorkClassificationOptions={ddlWorkClassification}
        init={init}
        handleClose={handleClose}
      />
    </div>
  );
}

export default ViewRequest;
