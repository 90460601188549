/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Row, Input, Select, Radio } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import API from "../../api/backend/request";
import KBN from "../../api/backend/kbn";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import "./approve.scss";

import {
  ToTopOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import {
  renderOption,
  MSG_CATCH,
  showMessage,
  statusRes,
  getQuerySearch,
  getNameFile,
  getDotFile,
  isEmpty,
} from "../../constants/utils";

import {
  STYLE,
  COMMON_CONSTANT,
  KEY,
  lang,
} from "../../constants/common.const";
import { Content } from "antd/lib/layout/layout";
import classPopup from "../../models/control/popup";
import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import { format } from "react-string-format";

const { TextArea } = Input;

const formDataAttach = new FormData();
let indexFile = 0;

function ConfirmRequest() {
  let { id } = useParams();
  const history = useHistory();
  let [initPage, setInitPage] = useState(true);

  const { isAuth } = useSelector((state) => state.auth);
  const [txtPopupUser, setTxtPopupUser] = useState(new classText());

  let [showLoading, setShowLoading] = useState(false);

  const [rejectOptionValue, setReJectOptionValue] = useState(1);
  const onChangeRejectOption = (e) => {
    setReJectOptionValue(e.target.value);
  };
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // In-Popup
  let [fileAttachErrorMessage, setFileAttachErrorMessage] = useState("");
  let [tblFlowAttach, setTblFlowAttach] = useState([]);
  const fileEl = useRef(null);
  let [statusConfirm, setStatusConfirm] = useState(new classPopup());
  let [txtReasonInput, setTxtReasonInput] = useState(new classText());
  let [txtRequestStatus, setTxtRequestStatus] = useState("");
  let [useWorkClassification, setUseWorkClassification] = useState(false);
  let [ddlWorkClassification, setddlWorkClassification] = useState(
    new classDdl()
  );
  let [nextFlow, setNextFlow] = useState(null);

  const init = () => {
    // Open loading
    openLoadingSpinner();

    if (!isAuth) {
      return;
    }

    let querySearch = getQuerySearch();
    let mode = querySearch.action ? querySearch.action.toUpperCase() : "";
    let customTitle = false;
    let title;
    let type;

    setInitPage(true);

    if (mode === COMMON_CONSTANT.REJECT) {
      // Reject mode

      customTitle = true;
      title = lang.REJECT_CONFIRM_TITLE;
      type = COMMON_CONSTANT.REJECT;
    } else if (mode === COMMON_CONSTANT.APPROVE) {
      // Approve mode

      customTitle = true;
      title = lang.APPROVE_CONFIRM_TITLE;
      type = COMMON_CONSTANT.APPROVE;
    } else if (mode === COMMON_CONSTANT.COMPLETE) {
      // Complete mode
      customTitle = true;

      if (useWorkClassification) {
        title = lang.COMPLETE_WORK_SELECTION_COMFIRM_TITLE;
      } else {
        title = lang.COMPLETE_COMFIRM_TITLE;
      }

      type = COMMON_CONSTANT.COMPLETE;
    } else {
      // other
      goToRequest();
      return;
    }

    setStatusConfirm({
      ...statusConfirm,
      id: id,
      title: title,
      customTitle: customTitle,
      type: type,
    });

    Promise.all([API.getDetail(id), KBN.getWorkClassificationDropDown()])
      .then((res) => {
        let data = res[0] ? res[0].data : null;
        let wcOption = res[1] ? res[1].data.data : [];

        setddlWorkClassification({
          ...ddlWorkClassification,
          options: wcOption,
          label: data.workClassificationName,
          value: data.workClassificationCode,
        });

        if (!data) {
          throw new Error();
        }

        let isNeedAction = data.needAction;
        if (isNeedAction != KEY.FLAG_ONE) {
          goToRequest();
        }
        if (data.useWorkClassification == 1) {
          setUseWorkClassification(true);
        }

        setTxtRequestStatus(data.status);

        // Request flow
        data.listRequestFlow = data.listRequestFlow.map((v) => {
          let row = {};
          row["StepOrder"] = v.stepOrder;
          row["UserLabel"] = v.userLabel;
          row["UserType"] = v.userType;
          row["UserTypeIndex"] = v.userTypeIndex;
          row["UserName"] = v.userName;
          return row;
        });

        let currentFlow = data.listRequestFlow.filter(
          (item) => item.StepOrder == data.step
        )[0];
        if (currentFlow && currentFlow.UserType != KEY.USER_TYPE_WORKER) {
          let nextWorkerFlow = data.listRequestFlow.filter(
            (item) => item.UserType == 2 && item.UserName == ""
          )[0];
          if (nextWorkerFlow) {
            nextWorkerFlow = {
              ...nextWorkerFlow,
              Required: nextWorkerFlow.StepOrder == data.step + 1,
            };
            setNextFlow(nextWorkerFlow);
          }
        }
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setTimeout(() => {
          setInitPage(false);
          closeLoadingSpinner();
        }, 1); // waiting for setup
      });
  };

  // Init
  useEffect(init, [id, isAuth]);

  useEffect(() => {});

  function backToSearch() {
    history.push("/RequestList");
  }

  function goToRequest() {
    history.push("/ViewRequest/" + id);
  }

  function changeStatus(data) {
    statusConfirm = data || statusConfirm;
    let flagErr = false;

    // Validate required
    if (statusConfirm.type != COMMON_CONSTANT.REJECT) {
      if (nextFlow && nextFlow.UserName == "" && nextFlow.Required) {
        if (!txtPopupUser.users || txtPopupUser.users.length == 0) {
          showMessage(
            KEY.ERROR,
            format(
              COMMON_CONSTANT.WF_E002,
              nextFlow.UserTypeIndex + "(" + nextFlow.UserLabel + ")"
            ),
            goToRequest
          );
          flagErr = true;
        }
      }
    }

    if (statusConfirm.type != COMMON_CONSTANT.APPROVE) {
      if (!txtReasonInput.value || txtReasonInput.value.trim() === "") {
        setTxtReasonInput({ ...txtReasonInput, error: true });
        flagErr = true;
      }
    }

    if (
      statusConfirm.type == COMMON_CONSTANT.COMPLETE &&
      useWorkClassification
    ) {
      if (!ddlWorkClassification.value || ddlWorkClassification.value === "") {
        setddlWorkClassification({ ...ddlWorkClassification, error: true });
        flagErr = true;
      }
    }

    if (flagErr) {
      return;
    }

    openLoadingSpinner();

    // Create Form data
    const formData = new FormData();

    // Create Object
    let formInput = {
      Action: statusConfirm.type,
      Remark: txtReasonInput.value,
      RejectOption: rejectOptionValue,
    };

    // FILE
    if (tblFlowAttach.length > 0) {
      // Add to obj
      formInput["ListFlowAttachment"] = tblFlowAttach.map((v) => {
        v["FileName"] = v.FileName + "." + v.DotName; // Full file name
        return v;
      });
      // Add list file to FormDATA
      tblFlowAttach.forEach((v, i) => {
        formData.append(`file[${i}]`, v.file);
      });
    }

    if (statusConfirm.type == COMMON_CONSTANT.COMPLETE) {
      formInput = {
        ...formInput,
        WorkClassificationCode: ddlWorkClassification.value,
      };
    }

    formData.append("data", JSON.stringify(formInput));

    // Call
    API.changeStatus(formData, statusConfirm.id)
      .then((res) => {
        if (statusRes(res)) {
          setTxtReasonInput(new classText());
          setTxtPopupUser(new classText());
          // search_onClick();
          showMessage(
            KEY.INFO,
            showNotifiPopup(statusConfirm.type),
            goToRequest
          );
        } else {
          showMessage(KEY.ERROR, res.data.msg);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close
        closeLoadingSpinner();
      });
  }

  function showNotifiPopup(mode) {
    let message;

    if (mode === COMMON_CONSTANT.REJECT) {
      // Reject mode
      message = lang.REJECT_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.APPROVE) {
      // Approve mode
      message = lang.APPROVE_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.COMPLETE) {
      // Resolve mode
      if (useWorkClassification) {
        message = lang.REGISTER_WORK_SELECTION_SUCCESS;
      } else {
        message = lang.SOLVED_NORMALLY;
      }
    } else {
      message = COMMON_CONSTANT.WF_C003;
    }
    return message;
  }

  function readAttachFileUrl(ev) {
    let el = ev.target;
    if (el.files && el.files[0]) {
      if (el.files[0].size > 102400000) {
        setFileAttachErrorMessage(COMMON_CONSTANT.WF_E001);
        el.value = ""; // Clear file
        return;
      }

      formDataAttach.append(indexFile++, el.files[0]);
      el.value = ""; // Clear file
      // Render
      renderTableAttach();
    }
  }
  function renderTableAttach() {
    let list = [...tblFlowAttach];
    let id = indexFile - 1;
    let file = formDataAttach.get(id);
    let row = {};
    row["ItemOrder"] = id; // Key
    row["FileName"] = getNameFile(file.name);
    row["DotName"] = getDotFile(file.name);
    row["Description"] = "";
    row["file"] = file; // File
    list.push(row);
    setTblFlowAttach(list);
  }

  return (
    <div className="des-content approve-screen">
      <Loading show={showLoading}></Loading>
      <TitlePage name={"依頼確認"} />
      <Content
        className="site-layout-background modal-custom-content"
        style={{
          margin: "24px calc(25% - 70px)",
          padding: 24,
        }}
      >
        <div className="modal-custom-icon">
          <ExclamationCircleOutlined />
        </div>

        <div className="modal-custom-content">
          <span className="modal-custom-title">{statusConfirm.title}</span>

          {String(statusConfirm.type) === COMMON_CONSTANT.COMPLETE &&
            Boolean(useWorkClassification) && (
              <>
                <Row>
                  <Col span={24} className="mt10">
                    <span className="fs12 pt5 mb5">
                      {lang.WORK_CLASSIFICATION}
                      <label className="blod ml5 mark-required">*</label>
                    </span>
                  </Col>

                  <Col span={24}>
                    <Select
                      ref={ddlWorkClassification}
                      value={ddlWorkClassification.label}
                      options={ddlWorkClassification.options}
                      className={
                        ddlWorkClassification.error
                          ? `${STYLE.BORDER_RED} w100 fs14 f-lighter`
                          : "w100 fs14 f-lighter"
                      }
                      onChange={(value, option) => {
                        setddlWorkClassification({
                          ...ddlWorkClassification,
                          value: option.key,
                          label: value,
                        });
                      }}
                    >
                      {renderOption(ddlWorkClassification.options)}
                    </Select>
                  </Col>
                </Row>
              </>
            )}

          <Row>
            <span className="fs12 pt5 mb5">
              {lang.COMMENT}
              {String(statusConfirm.type) != COMMON_CONSTANT.APPROVE && (
                <>
                  <label className="blod ml5 mark-required">*</label>
                </>
              )}
            </span>
          </Row>
          <Row>
            <TextArea
              rows={3}
              className={txtReasonInput.error ? STYLE.BORDER_RED : ""}
              value={txtReasonInput.value}
              onChange={(e) => {
                setTxtReasonInput({
                  ...txtReasonInput,
                  value: e.target.value,
                  error: false,
                });
              }}
            />
          </Row>

          {String(statusConfirm.type) === COMMON_CONSTANT.REJECT &&
            String(txtRequestStatus) === KEY.STATUS_CONFIRM_WORK && (
              <>
                <Row style={{ marginTop: 10 }}>
                  {/* <Col span={24} className="mt10">
                            <span className="fs12 pt5 mb5">
                              {lang.WORK_CLASSIFICATION}
                              <label className="blod ml5 mark-required">
                                *
                              </label>
                            </span>
                          </Col> */}

                  <Col span={24}>
                    <Radio.Group
                      onChange={onChangeRejectOption}
                      value={rejectOptionValue}
                    >
                      <Radio value={1}>
                        {lang.REJECT_AND_SEND_BACK_TO_WORKER}
                      </Radio>
                      <Radio value={2}>
                        {lang.REJECT_AND_SEND_BACK_TO_REQUESTER}
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </>
            )}

          {String(statusConfirm.type) === COMMON_CONSTANT.COMPLETE && (
            <>
              <Row>
                <Col span={24} className="mt10">
                  <span className="fs12 pt5 mb5">
                    {lang.ATTACH}
                    {/* <label className="blod ml5 mark-required">*</label> */}
                  </span>
                </Col>

                <Col span={24}>
                  <input
                    type="file"
                    className="d-none"
                    // accept=".xls,.xlsx"
                    ref={fileEl}
                    onChange={readAttachFileUrl}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      setFileAttachErrorMessage("");
                      fileEl.current.click();
                    }}
                    className="buttonPC button--outline --todo-- wAuto"
                    style={{
                      backgroundColor: "#f7f9fc",
                      marginBottom: 10,
                      borderColor: "lightgray",
                    }}
                  >
                    <ToTopOutlined />
                    {lang.ATTACH}
                  </Button>

                  <div className="red fs14 mb10">{fileAttachErrorMessage}</div>

                  {tblFlowAttach.length > 0 &&
                    tblFlowAttach.map((item, index) => {
                      let urlFile = item.file
                        ? URL.createObjectURL(item.file)
                        : item.attachmentUrl;

                      let fileName = item.file
                        ? item.file.name
                        : item.FileName + "." + item.DotName;

                      return (
                        <Row
                          className="mb10"
                          key={index}
                          style={{ alignItems: "center" }}
                        >
                          <Col span={2}>
                            <Button
                              onClick={() => {
                                let list = [...tblFlowAttach].filter(
                                  (x) => x["ItemOrder"] !== item["ItemOrder"]
                                );
                                setTblFlowAttach(list);
                              }}
                              className="btn-deletefile mr8"
                              style={{
                                background: "#f7f9fc",
                                minWidth: "unset",
                                height: 30,
                              }}
                              icon={<DeleteOutlined />}
                            />
                          </Col>
                          <Col span={21} className="text-cut">
                            <a
                              href={urlFile}
                              download={fileName}
                              target="_blank"
                            >
                              {fileName}
                            </a>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            </>
          )}
        </div>
      </Content>
      <Row
        justify="end"
        className="mt10 mb10"
        style={{
          margin: "24px calc(25% - 70px)",
        }}
      >
        <Button
          className="buttonPC button--warning2 wAuto mr15"
          onClick={() => {
            changeStatus();
          }}
        >
          {lang.OK}
        </Button>
        <Button onClick={backToSearch}>{lang.CANCEL}</Button>
      </Row>
    </div>
  );
}

export default ConfirmRequest;
