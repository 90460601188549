import React, { useState, useContext, useEffect } from "react";
import FlowBuilder, { NodeContext } from "react-flow-builder";

import { Tooltip } from "antd";
import {
  DeleteOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { KEY, lang } from "../../constants/common.const";

import StartIcon from "@src/styles/icon/flag_start_icon.png";
import FinishIcon from "@src/styles/icon/flag_finish_icon.png";
import UserIcon from "@src/styles/icon/circle_user_icon.png";

import "./requestFlow.css";

const RequestFlow = ({ requester, flowObject }) => {
  const StartNodeDisplay = () => {
    const node = useContext(NodeContext);
    return (
      <Tooltip title={node.requester} placement="right">
        <img style={{ height: "64px" }} src={UserIcon} />
      </Tooltip>
    );
  };

  const EndNodeDisplay = () => {
    const node = useContext(NodeContext);
    return <img style={{ height: "64px" }} src={FinishIcon} />;
  };

  const NodeDisplay = () => {
    const node = useContext(NodeContext);
    return (
      <Tooltip title={node.userName} placement="right">
        <div
          className={`other-node ${node.className}`}
          style={{
            backgroundColor: node.color,
            fontWeight: "bold",
          }}
        >
          {node.typeIndex}
          {node.name && (
            <>
              <br />
              <div className="userLabel-style">
                {"("}
                {node.name}
                {")"}
              </div>
            </>
          )}

          {node.stepOrder != node.currentStep && (
            <>
              {node.status == KEY.FLOW_STATUS_REJECT && (
                <>
                  <ExclamationCircleFilled
                    style={{
                      fontSize: 25,
                      position: "absolute",
                      top: 20,
                      right: 10,
                      color: "#fff",
                    }}
                  />
                </>
              )}

              {(node.status == KEY.FLOW_STATUS_APPROVED ||
                node.status == KEY.FLOW_STATUS_COMPLETE) && (
                <>
                  <CheckCircleFilled
                    style={{
                      fontSize: 25,
                      position: "absolute",
                      top: 20,
                      right: 10,
                      color: "#fff",
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Tooltip>
    );
  };

  const registerNodes = [
    {
      type: "start",
      name: "start node",
      displayComponent: StartNodeDisplay,
      isStart: true,
    },
    {
      type: "end",
      name: "end node",
      displayComponent: EndNodeDisplay,
      isEnd: true,
    },
    {
      type: "node",
      name: "Common Node",
      displayComponent: NodeDisplay,
    },
  ];

  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    if (flowObject && flowObject.length > 0) {
      let defaultNodes = [
        {
          id: "0",
          type: "start",
          name: "START",
          requester: requester,
          path: ["0"],
        },
      ];

      const nodes = flowObject.map((item, index) => {
        let node = {};
        node.id = item.StepOrder;
        node.type = "node";
        node.userName = item.UserNameDisplay;
        node.color = item.PanelColor;
        node.typeIndex = item.UserTypeIndex;
        node.name = item.UserLabel;
        node.path = [item.StepOrder];
        node.status = item.Status;
        node.stepOrder = item.StepOrder;
        node.currentStep = item.CurrentStep;
        return node;
      });

      defaultNodes = [...defaultNodes, ...nodes];
      defaultNodes = [
        ...defaultNodes,
        {
          id: flowObject.length + 1,
          type: "end",
          name: "END",
          path: [flowObject.length + 1],
        },
      ];

      setNodes(defaultNodes);
    } else {
      setNodes([]);
    }
  }, [flowObject]);

  const handleChange = (nodes) => {
    setNodes(nodes);
  };

  return (
    <FlowBuilder
      showArrow
      readonly={true}
      onChange={handleChange}
      nodes={nodes}
      registerNodes={registerNodes}
    />
  );
};

export default RequestFlow;
