import React, { useState, useEffect } from "react";
import { Col, Row, Collapse, Input } from "antd";
import classText from "../../models/control/text";
import AutoMail from "./autoMail";
import BreakLine from "./breakLine";
import {
  DeleteOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import StatusConst from "../../constants/status.const";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import "./userPanel.scss";
import { format } from "react-string-format";

//Declare global variables
const { TextArea } = Input;
const { Panel } = Collapse;

function UserPanel({
  editMode = false,
  getUser = new classText(),
  setUser = null,
  setUserRef = null,
  className,
  typeSearch = null,
  placeholder,
  onDelete,
  ...props
}) {
  const [txtRemark, setTxtRemark] = useState({
    ...new classText(),
    value: props.flowObject.Remark,
  });

  const [tblAttach, setTblAttach] = useState(
    props.flowObject.ListFlowAttachment
  );

  const [readOnlyMode, setReadOnlyMode] = useState(
    props.flowObject.StepOrder < props.flowObject.CurrentStep ||
      props.flowObject.ViewMode ||
      props.flowObject.UserNameRef
      ? true
      : false
  );

  const [activeKey, setActiveKey] = useState(
    props.viewMode ? props.flowObject.StepOrder : 1
  );

  const [headerText, setHeaderText] = useState(props.flowObject.UserTypeIndex);
  const [userErrorMessage, setUserErrorMessage] = useState("");

  useEffect(() => {
    if (props.flowObject.UserLabel && props.flowObject.UserLabel != "") {
      setHeaderText(
        props.flowObject.UserTypeIndex + ": " + props.flowObject.UserLabel
      );
    }

    if (props.flowObject.StepOrder == props.flowObject.CurrentStep + 1) {
      if (props.flowObject.UserName == "") {
        setUserErrorMessage(
          "※" +
            format(
              COMMON_CONSTANT.WF_E002,
              props.flowObject.UserTypeIndex +
                "(" +
                props.flowObject.UserLabel +
                ")"
            )
        );
      }
    }
  }, [props.flowObject.UserLabel]);

  const genDeleteIcon = () => {
    return (
      <>
        {props.flowObject.CanDelete === KEY.FLAG_ONE &&
          !props.flowObject.Status && (
            <>
              <DeleteOutlined
                onClick={(event) => {
                  // If you don't want click extra trigger collapse, you can prevent this:
                  event.stopPropagation();
                  onDelete && onDelete(props.flowObject);
                }}
              />
            </>
          )}

        {props.flowObject.StepOrder != props.flowObject.CurrentStep &&
          props.flowObject.Status &&
          props.flowObject.Status != KEY.ACTION_NONE &&
          props.flowObject.Status != KEY.ACTION_CANCEL && (
            <>
              <label style={{ marginRight: 30 }}>
                {StatusConst.STATUS_LABEL[props.flowObject.Status]}
                {": "}
                {props.flowObject.UpdateDatetimeDisplay}{" "}
              </label>

              {props.flowObject.Status == KEY.FLOW_STATUS_REJECT && (
                <>
                  <ExclamationCircleFilled
                    style={{
                      fontSize: 25,
                      position: "absolute",
                      top: 10,
                      right: 10,
                      color: "#fff",
                    }}
                  />
                </>
              )}

              {(props.flowObject.Status == KEY.FLOW_STATUS_APPROVED ||
                props.flowObject.Status == KEY.FLOW_STATUS_COMPLETE) && (
                <>
                  <CheckCircleFilled
                    style={{
                      fontSize: 25,
                      position: "absolute",
                      top: 10,
                      right: 10,
                      color: "#fff",
                    }}
                  />
                </>
              )}
            </>
          )}
      </>
    );
  };

  return (
    <Col span={24} className={"mb15"}>
      <Collapse
        className="userPanel"
        defaultActiveKey={
          (props.flowObject.StepOrder == props.flowObject.CurrentStep ||
            (props.flowObject.Status != KEY.ACTION_APPROVED &&
              props.flowObject.Status != KEY.ACTION_COMPLETE)) &&
          activeKey
        }
      >
        <Panel
          style={{
            backgroundColor: props.flowObject.PanelColor,
            // fontWeight: "bold",
          }}
          header={headerText}
          key={activeKey}
          extra={genDeleteIcon()}
        >
          <Row>
            <Col span={24}>
              <Row className={"mb15"}>
                <Col flex={"120px"} className={"row-input-col-first"}>
                  <Row justify="space-between">
                    <Col span={24} className="right pr20">
                      <label className="row-input-label ">
                        {props.flowObject.UserTypeIndex}
                      </label>
                      {!props.viewMode &&
                        !readOnlyMode &&
                        props.flowObject.Required && (
                          <label className="blod ml5 mark-required">*</label>
                        )}
                    </Col>
                  </Row>
                </Col>
                <Col flex={"auto"} className="row-input-col-2">
                  <AutoMail
                    get={getUser}
                    set={setUser}
                    setRef={setUserRef}
                    placeholder={placeholder}
                    className={
                      props.viewMode || readOnlyMode
                        ? "autoMail-disabled"
                        : className
                    }
                    disabled={props.viewMode || readOnlyMode}
                  ></AutoMail>

                  {getUser.users.length == 0 && (
                    <>
                      <div className="red fs14 f-bold mt10">
                        {userErrorMessage}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            {props.viewMode && (
              <>
                <BreakLine />
                <Col span={24}>
                  <Row className={"mt15 mb15"}>
                    <Col flex={"120px"} className={"row-input-col-first"}>
                      <Row justify="space-between">
                        <Col span={24} className="right pr20">
                          <label className="row-input-label ">
                            {lang.COMMENT}
                          </label>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex={"auto"} className="row-input-col-2">
                      <TextArea
                        disabled={props.viewMode}
                        value={txtRemark.value}
                        rows={2}
                      />
                    </Col>
                  </Row>
                </Col>

                {tblAttach.length > 0 && (
                  <>
                    <BreakLine />
                    <Col span={24}>
                      <Row className={"mt15"}>
                        <Col flex={"120px"} className={"row-input-col-first"}>
                          <Row justify="space-between">
                            <Col span={24} className="right pr20">
                              <label className="row-input-label ">
                                {lang.ATTACH}
                              </label>
                            </Col>
                          </Row>
                        </Col>
                        <Col flex={"auto"} className="row-input-col-2">
                          {tblAttach.length > 0 &&
                            tblAttach.map((item, index) => {
                              let urlFile = item.attachmentUrl;
                              let fileName = item.fileName;

                              return (
                                <Row
                                  className="mb10"
                                  key={index}
                                  style={{ alignItems: "center" }}
                                >
                                  <Col>
                                    <a
                                      href={urlFile}
                                      download={fileName}
                                      target="_blank"
                                    >
                                      {fileName}
                                    </a>
                                  </Col>
                                </Row>
                              );
                            })}
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        </Panel>
      </Collapse>
    </Col>
  );
}

export default UserPanel;
