const STATUS_LIST = [
  {
    key: "1",
    action: "APPROVE",
    color: "#2FAD5A",
  },
  {
    key: "2",
    action: "REJECT",
    color: "#ED3A3E",
  },
  // {
  //   key: "3",
  //   action: "CANCEL",
  //   color: "#808080",
  // },
  {
    key: "4",
    action: "COMPLETE",
    color: "#2FAD5A",
  },
  {
    key: "5",
    action: "DOING",
    color: "#ffa326",
  },
];

const STATUS_CL_MANAGEMENT = {
  // Draft
  0: "#6c757d",
  下書: "#6c757d",
  // Save
  1: "#0080CB",
  正式: "#0080CB",
};
const STATUS_COLOR = {
  1: "#2FAD5A",
  2: "#2FAD5A",
  3: "#2FAD5A",
  4: "#ED3A3E",
  5: "#0080cb",
  6: "#ED3A3E",
  7: "#808080",
};

const STATUS_LABEL = {
  1: "承認日",
  2: "否認日",
  4: "作業完了日",
};

// const FLOW_USER_TYPE = [
//   { key: 1, value: "承認者" },z
//   { key: 2, value: "作業者" },
// ];

const FLOW_USER_TYPE = {
  1: "承認者", // approver
  2: "作業者", // worker
  3: "作業承認者", // approve work
};

const STATUS_HOME_BANNER = {
  NOTSEEN: "0",
  SEEN: "1",
};

export default {
  FLOW_USER_TYPE: FLOW_USER_TYPE,
  STATUS_LIST: STATUS_LIST,
  STATUS_LABEL: STATUS_LABEL,
  STATUS_COLOR: STATUS_COLOR,
  STATUS_HOME_BANNER: STATUS_HOME_BANNER,
};
