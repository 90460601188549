import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Typography,
  Input,
  Button,
  Modal,
  Select,
  Radio,
} from "antd";
import "./popup.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loading from "../../component/Desktop/loading/loading";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import AutoMail from "../../component/Desktop/autoMail";

import {
  STYLE,
  COMMON_CONSTANT,
  KEY,
  lang,
} from "../../constants/common.const";
import {
  renderOption,
  MSG_CATCH,
  showMessage,
  statusRes,
  getNameFile,
  getDotFile,
} from "../../constants/utils";

import API from "../../api/backend/request";

import Draggable from "react-draggable";
import {
  ToTopOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { format } from "react-string-format";

const { TextArea } = Input;
const { Paragraph } = Typography;

const formDataAttach = new FormData();
let indexFile = 0;

function ConfirmModal(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const fileEl = useRef(null);
  let [tblFlowAttach, setTblFlowAttach] = useState([]);
  let [fileAttachErrorMessage, setFileAttachErrorMessage] = useState("");
  let [txtReasonInput, settxtReasonInput] = useState(new classText());
  const okBtnRef = useRef();
  const cancelBtnRef = useRef();

  const [rejectOptionValue, setReJectOptionValue] = useState(1);
  const onChangeRejectOption = (e) => {
    setReJectOptionValue(e.target.value);
  };

  const {
    useWorkClassification,
    ddlWorkClassificationOptions,
    init,
    popupConfirm,
    listSelectedRequest,
    handleClose,
  } = props;

  let [ddlWorkClassification, setddlWorkClassification] = useState(
    new classDdl()
  );

  let [visible, setVisible] = useState(false);
  let [showLoading, setShowLoading] = useState(false);
  const [txtPopupUser, setTxtPopupUser] = useState(new classText());
  const [txtPopupUserRef, setTxtPopupUserRef] = useState(null);

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  useEffect(() => {
    setFileAttachErrorMessage("");
    settxtReasonInput(new classText());
    setTxtPopupUser(new classText());
    setddlWorkClassification({
      ...ddlWorkClassification,
      // value: "",
      error: false,
    });
    setTblFlowAttach([]);

    setddlWorkClassification(ddlWorkClassificationOptions);
    setVisible(popupConfirm.show);
  }, [props]);

  function readAttachFileUrl(ev) {
    let el = ev.target;
    if (el.files && el.files[0]) {
      if (el.files[0].size > 102400000) {
        setFileAttachErrorMessage(COMMON_CONSTANT.WF_E001);
        el.value = ""; // Clear file
        return;
      }

      formDataAttach.append(indexFile++, el.files[0]);
      el.value = ""; // Clear file
      // Render
      renderTableAttach();
    }
  }

  function renderTableAttach() {
    let list = [...tblFlowAttach];
    let id = indexFile - 1;
    let file = formDataAttach.get(id);
    let row = {};
    row["ItemOrder"] = id; // Key
    row["FileName"] = getNameFile(file.name);
    row["DotName"] = getDotFile(file.name);
    row["Description"] = "";
    row["file"] = file; // File
    list.push(row);
    setTblFlowAttach(list);
  }

  // Start: Draggable modal
  const [disabledDraggable, setDisabledDraggable] = useState(false);
  const draggleRef = useRef(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  // End: Draggable modal

  function changeStatus() {
    let flagErr = false;

    // Validate required

    if (
      popupConfirm.type == COMMON_CONSTANT.COMPLETE &&
      useWorkClassification
    ) {
      if (!ddlWorkClassification.value || ddlWorkClassification.value === "") {
        setddlWorkClassification({ ...ddlWorkClassification, error: true });
        flagErr = true;
      }
    }

    if (popupConfirm.type != COMMON_CONSTANT.APPROVE) {
      if (!txtReasonInput.value || txtReasonInput.value.trim() === "") {
        settxtReasonInput({ ...txtReasonInput, error: true });
        flagErr = true;
      }
    }

    if (flagErr) {
      return;
    }

    // Create Form data
    const formData = new FormData();

    // Create Object
    let formInput = {
      Action: popupConfirm.type,
      Remark: txtReasonInput.value,
      RejectOption: rejectOptionValue,
    };

    if (popupConfirm.type == COMMON_CONSTANT.COMPLETE) {
      formInput = {
        ...formInput,
        WorkClassificationCode: ddlWorkClassification.value,
      };
    }

    // FILE
    if (tblFlowAttach.length > 0) {
      // Add to obj
      formInput["ListFlowAttachment"] = tblFlowAttach.map((v) => {
        v["FileName"] = v.FileName + "." + v.DotName; // Full file name
        return v;
      });
      // Add list file to FormDATA
      tblFlowAttach.forEach((v, i) => {
        formData.append(`file[${i}]`, v.file);
      });
    }

    if (listSelectedRequest && listSelectedRequest.length > 0) {
      formInput["ListId"] = listSelectedRequest;
    }

    formData.append("data", JSON.stringify(formInput));

    handleClose();

    if (popupConfirm.type != COMMON_CONSTANT.REJECT) {
      if (
        popupConfirm.flowObject &&
        popupConfirm.flowObject.UserName == "" &&
        popupConfirm.flowObject.Required
      ) {
        showMessage(
          KEY.ERROR,
          format(
            COMMON_CONSTANT.WF_E002,
            popupConfirm.flowObject.UserTypeIndex +
              "(" +
              popupConfirm.flowObject.UserLabel +
              ")"
          ),
          goToRequest
        );
        return;
      }
    }

    openLoadingSpinner();
    // Call
    API.changeStatus(formData, popupConfirm.id)
      .then((res) => {
        if (statusRes(res)) {
          // Reset data
          settxtReasonInput(new classText());
          setTxtPopupUser(new classText());
          setTblFlowAttach([]);

          showNotifiPopup(popupConfirm.type);
        } else {
          showMessage(KEY.ERROR, res.data.msg);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        // Close
        closeLoadingSpinner();
      });
  }

  function goToRequest() {
    history.push("/ViewRequest/" + popupConfirm.id);
  }

  function showNotifiPopup(mode) {
    mode = String(mode);
    let title;

    if (mode === COMMON_CONSTANT.REJECT) {
      // Reject mode
      title = lang.REJECT_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.APPROVE) {
      // Approve mode
      title = lang.APPROVE_CHECKLIST_SUCCESS;
    } else if (mode === COMMON_CONSTANT.COMPLETE) {
      // Resolve mode
      if (useWorkClassification) {
        title = lang.REGISTER_WORK_SELECTION_SUCCESS;
      } else {
        title = lang.SOLVED_NORMALLY;
      }
    } else {
      // Other
    }
    showMessage(KEY.INFO, title, init);
  }

  return (
    <>
      <Loading show={showLoading}></Loading>
      <Modal
        centered
        closeIcon={<></>}
        className="modal-msg modal-warning3"
        title={
          <>
            <ExclamationCircleOutlined />
            <div
              className="modal-custom-content"
              style={{
                width: "100%",
                cursor: "move",
              }}
            >
              {popupConfirm.title}
              {popupConfirm.customTitle && (
                <>
                  {String(popupConfirm.type) === COMMON_CONSTANT.COMPLETE &&
                    Boolean(useWorkClassification) && (
                      <>
                        <Row>
                          <Col span={24} className="mt10">
                            <span className="fs12 pt5 mb5">
                              {lang.WORK_CLASSIFICATION}
                              <label className="blod ml5 mark-required">
                                *
                              </label>
                            </span>
                          </Col>

                          <Col span={24}>
                            <Select
                              onMouseOver={() => {
                                setDisabledDraggable(true);
                              }}
                              onMouseOut={() => {
                                setDisabledDraggable(false);
                              }}
                              onPointerOver={() => {
                                setDisabledDraggable(true);
                              }}
                              onPointerOut={() => {
                                setDisabledDraggable(false);
                              }}
                              ref={ddlWorkClassification}
                              value={ddlWorkClassification.label}
                              options={ddlWorkClassification.options}
                              className={
                                ddlWorkClassification.error
                                  ? `${STYLE.BORDER_RED} w100 fs14 f-lighter`
                                  : "w100 fs14 f-lighter"
                              }
                              onChange={(value, option) => {
                                setddlWorkClassification({
                                  ...ddlWorkClassification,
                                  value: option.key,
                                  label: value,
                                });
                              }}
                            >
                              {renderOption(ddlWorkClassification.options)}
                            </Select>
                          </Col>
                        </Row>
                      </>
                    )}

                  <Row>
                    <span className="fs12 pt5 mb5">
                      {lang.COMMENT}
                      {popupConfirm.type != COMMON_CONSTANT.APPROVE && (
                        <>
                          <label className="blod ml5 mark-required">*</label>
                        </>
                      )}
                    </span>
                  </Row>
                  <Row>
                    <TextArea
                      onMouseOver={() => {
                        setDisabledDraggable(true);
                      }}
                      onMouseOut={() => {
                        setDisabledDraggable(false);
                      }}
                      onPointerOver={() => {
                        setDisabledDraggable(true);
                      }}
                      onPointerOut={() => {
                        setDisabledDraggable(false);
                      }}
                      rows={3}
                      className={txtReasonInput.error ? STYLE.BORDER_RED : ""}
                      value={txtReasonInput.value}
                      onChange={(e) => {
                        settxtReasonInput({
                          ...txtReasonInput,
                          value: e.target.value,
                          error: false,
                        });
                      }}
                    />
                  </Row>

                  {String(popupConfirm.type) === COMMON_CONSTANT.REJECT &&
                    String(popupConfirm.requestStatus) ===
                      KEY.STATUS_CONFIRM_WORK && (
                      <>
                        <Row
                          style={{ marginTop: 10 }}
                          onMouseOver={() => {
                            setDisabledDraggable(true);
                          }}
                          onMouseOut={() => {
                            setDisabledDraggable(false);
                          }}
                          onPointerOver={() => {
                            setDisabledDraggable(true);
                          }}
                          onPointerOut={() => {
                            setDisabledDraggable(false);
                          }}
                        >
                          {/* <Col span={24} className="mt10">
                            <span className="fs12 pt5 mb5">
                              {lang.WORK_CLASSIFICATION}
                              <label className="blod ml5 mark-required">
                                *
                              </label>
                            </span>
                          </Col> */}

                          <Col span={24}>
                            <Radio.Group
                              onChange={onChangeRejectOption}
                              value={rejectOptionValue}
                            >
                              <Radio value={1}>
                                {lang.REJECT_AND_SEND_BACK_TO_WORKER}
                              </Radio>
                              <Radio value={2}>
                                {lang.REJECT_AND_SEND_BACK_TO_REQUESTER}
                              </Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                      </>
                    )}

                  {String(popupConfirm.type) === COMMON_CONSTANT.COMPLETE && (
                    <>
                      <Row>
                        <Col span={24} className="mt10">
                          <span className="fs12 pt5 mb5">{lang.ATTACH}</span>
                        </Col>

                        <Col span={24}>
                          <input
                            onMouseOver={() => {
                              setDisabledDraggable(true);
                            }}
                            onMouseOut={() => {
                              setDisabledDraggable(false);
                            }}
                            onPointerOver={() => {
                              setDisabledDraggable(true);
                            }}
                            onPointerOut={() => {
                              setDisabledDraggable(false);
                            }}
                            type="file"
                            className="d-none"
                            // accept=".xls,.xlsx"
                            ref={fileEl}
                            onChange={readAttachFileUrl}
                          />
                          <Button
                            onMouseOver={() => {
                              setDisabledDraggable(true);
                            }}
                            onMouseOut={() => {
                              setDisabledDraggable(false);
                            }}
                            onPointerOver={() => {
                              setDisabledDraggable(true);
                            }}
                            onPointerOut={() => {
                              setDisabledDraggable(false);
                            }}
                            type="link"
                            onClick={() => {
                              setFileAttachErrorMessage("");
                              fileEl.current.click();
                            }}
                            className="buttonPC button--outline --todo-- wAuto"
                            style={{
                              backgroundColor: "#f7f9fc",
                              marginBottom: 10,
                              borderColor: "lightgray",
                            }}
                          >
                            <ToTopOutlined />
                            {lang.ATTACH}
                          </Button>

                          <div className="red fs12 f-lighter mb10">
                            {fileAttachErrorMessage}
                          </div>

                          {tblFlowAttach.length > 0 &&
                            tblFlowAttach.map((item, index) => {
                              let urlFile = item.file
                                ? URL.createObjectURL(item.file)
                                : item.attachmentUrl;

                              let fileName = item.file
                                ? item.file.name
                                : item.FileName + "." + item.DotName;

                              return (
                                <Row
                                  className="mb10"
                                  key={index}
                                  style={{ alignItems: "center" }}
                                >
                                  <Col span={2}>
                                    <Button
                                      onMouseOver={() => {
                                        setDisabledDraggable(true);
                                      }}
                                      onMouseOut={() => {
                                        setDisabledDraggable(false);
                                      }}
                                      onPointerOver={() => {
                                        setDisabledDraggable(true);
                                      }}
                                      onPointerOut={() => {
                                        setDisabledDraggable(false);
                                      }}
                                      onClick={() => {
                                        let list = [...tblFlowAttach].filter(
                                          (x) =>
                                            x["ItemOrder"] !== item["ItemOrder"]
                                        );
                                        setTblFlowAttach(list);
                                      }}
                                      className="btn-deletefile mr8"
                                      style={{
                                        background: "#f7f9fc",
                                        minWidth: "unset",
                                        height: 30,
                                      }}
                                      icon={<DeleteOutlined />}
                                    />
                                  </Col>
                                  <Col span={21} className="text-cut">
                                    <a
                                      onMouseOver={() => {
                                        setDisabledDraggable(true);
                                      }}
                                      onMouseOut={() => {
                                        setDisabledDraggable(false);
                                      }}
                                      onPointerOver={() => {
                                        setDisabledDraggable(true);
                                      }}
                                      onPointerOut={() => {
                                        setDisabledDraggable(false);
                                      }}
                                      href={urlFile}
                                      download={fileName}
                                      target="_blank"
                                    >
                                      {fileName}
                                    </a>
                                  </Col>
                                </Row>
                              );
                            })}
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        }
        modalRender={(modal) => (
          <Draggable
            disabled={disabledDraggable}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        visible={popupConfirm.show}
        footer={[
          <Button
            onMouseOver={() => {
              setDisabledDraggable(true);
            }}
            onMouseOut={() => {
              setDisabledDraggable(false);
            }}
            onPointerOver={() => {
              setDisabledDraggable(true);
            }}
            onPointerOut={() => {
              setDisabledDraggable(false);
            }}
            key={1}
            className="buttonPC button--warning2 wAuto"
            onClick={() => {
              changeStatus();
            }}
          >
            {lang.OK}
          </Button>,
          <Button
            onMouseOver={() => {
              setDisabledDraggable(true);
            }}
            onMouseOut={() => {
              setDisabledDraggable(false);
            }}
            onPointerOver={() => {
              setDisabledDraggable(true);
            }}
            onPointerOut={() => {
              setDisabledDraggable(false);
            }}
            key={2}
            onClick={handleClose}
          >
            {lang.CANCEL}
          </Button>,
        ]}
      ></Modal>
    </>
  );
}
export default ConfirmModal;
