/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  Col,
  Row,
  Select,
  Input,
  Button,
  Modal,
  DatePicker,
  Typography,
} from "antd";

import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import BreakLine from "../../component/Desktop/breakLine";
import FooterPage from "../../component/footer/footerPage";
import UserPanel from "../../component/Desktop/userPanel";
import RequestFlow from "../../component/Flow/requestFlow";

import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import STATUS from "@src/constants/status.const";
import API from "../../api/backend/request";
import KBN from "../../api/backend/kbn";
import CategoryAPI from "../../api/backend/category";

import {
  renderOption,
  MSG_CATCH,
  statusRes,
  getNameFile,
  getDotFile,
  showMessage,
  isEmpty,
} from "../../constants/utils";

import {
  CloseCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
  ToTopOutlined,
} from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import moment from "moment";

import { setIsUpdated } from "@src/redux/request";
import { format } from "react-string-format";

//Declare global variables
const { TextArea } = Input;
const { Paragraph } = Typography;

let FLAG_CHANGE_DATA = false;
const formDataAttach = new FormData();
let indexFile = 0;

function CreateRequest() {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  const [action, setAction] = useState(undefined);
  const [modeUpdateFlow, setModeUpdateFlow] = useState(undefined);
  const [modeCreate, setModeCreate] = useState(undefined);
  const [modeEdit, setModeEdit] = useState(undefined);
  const [modeClone, setModeClone] = useState(undefined);
  const [activeEditFlow, setActiveEditFlow] = useState(false);
  const { currentRouter } = useSelector((state) => state.router);
  const { isAuth, isAdmin, username, displayName } = useSelector(
    (state) => state.auth
  );
  let [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (id) {
      if (currentRouter.includes("/EditRequestFlow")) {
        setModeUpdateFlow(true);
      } else if (currentRouter.includes("/CloneRequest")) {
        setModeClone(true);
        setModeEdit(true);
      } else if (currentRouter.includes("/EditRequest")) {
        setModeEdit(true);
      }
    } else {
      setModeCreate(true);
      setModeEdit(true);
    }
  }, [currentRouter]);

  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  // Group 1
  let [txtId, settxtId] = useState(new classText());
  let [txtStatus, settxtStatus] = useState(new classText());
  let [txtStatusText, settxtStatusText] = useState(new classText());
  let [txtStep, settxtStep] = useState(new classText());
  let [txtRequester, settxtRequester] = useState(new classText());
  let [expectedCompleteDate, setExpectedCompleteDate] = useState(
    new classText()
  );
  let [requestedDateDisplay, setRequestedDateDisplay] = useState(
    new classText()
  );
  let [expectedCompleteDateDisplay, setExpectedCompleteDateDisplay] = useState(
    new classText()
  );
  let [txtTitle, settxtTitle] = useState(new classText());
  let [txtContent, settxtContent] = useState(new classText());
  let [ddlDepartment, setddlDepartment] = useState(new classDdl());
  let [ddlPriority, setddlPriority] = useState(new classDdl());
  let [ddlCategoryType, setDdlCategoryType] = useState(new classDdl());
  let [ddlCategory, setDdlCategory] = useState(new classDdl());

  // Focus
  let txtTitleEl = useRef(null);
  let expectedCompleteDateEl = useRef(null);
  let ddlPriorityEl = useRef(null);
  let ddlDepartmentEl = useRef(null);
  let txtContentEl = useRef(null);
  let ddlCategoryEl = useRef(null);
  let ddlCategoryTypeEl = useRef(null);

  // File
  const fileEl = useRef(null);
  let [fileAttachErrorMessage, setFileAttachErrorMessage] = useState("");
  let [tblAttach, setTblAttach] = useState([]);

  // Flow init
  const initFlow = "1,2,3";
  let [requestFlow, setRequestFlow] = useState([]);
  let [backupRequestFlow, setBackUpRequestFlow] = useState([]);
  let [listApprove, setApproverList] = useState([]);
  let [listWorker, setWorkerList] = useState([]);
  let [listConfirmApprove, setlistConfirmApprove] = useState([]);

  function countList(flowData) {
    const listApprove = flowData.filter(
      (e) => e.UserType == KEY.USER_TYPE_APPROVER
    );
    setApproverList(listApprove);
    const listWorker = flowData.filter(
      (e) => e.UserType == KEY.USER_TYPE_WORKER
    );
    setWorkerList(listWorker);
    const listConfirmApprove = flowData.filter(
      (e) => e.UserType == KEY.USER_TYPE_CONFIRM_APPROVER
    );
    setlistConfirmApprove(listConfirmApprove);
  }

  useEffect(() => {
    countList(requestFlow);

    var nextStep = txtStep.value + 1;
    if (modeUpdateFlow) {
      let nextWorkerFlow = requestFlow.filter(
        (item) => item.StepOrder == nextStep
      )[0];

      if (nextWorkerFlow && nextWorkerFlow.UserType == KEY.USER_TYPE_WORKER) {
        if (nextWorkerFlow.UserName != "") {
          setErrorMessage("");
        } else {
          setErrorMessage(
            "※" +
              format(
                COMMON_CONSTANT.WF_E002,
                nextWorkerFlow.UserTypeIndex +
                  "(" +
                  nextWorkerFlow.UserLabel +
                  ")"
              )
          );
        }
      }
    }
  }, [requestFlow]);

  useEffect(() => {
    if (!initPage) {
      let newList = getUserDataFromList(requestFlow);
      if (JSON.stringify(backupRequestFlow) != JSON.stringify(newList)) {
        FLAG_CHANGE_DATA = true;
        dispatch(setIsUpdated(FLAG_CHANGE_DATA));
      }
    }
  }, [backupRequestFlow, requestFlow]);

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
      dispatch(setIsUpdated(FLAG_CHANGE_DATA));
    }
  }, [
    txtTitle,
    txtTitleEl,
    ddlCategory,
    ddlCategoryEl,
    ddlCategoryType,
    ddlCategoryTypeEl,
    ddlDepartment,
    ddlDepartmentEl,
    expectedCompleteDate,
    expectedCompleteDateEl,
    txtContent,
    txtContentEl,
    ddlPriority,
    ddlPriorityEl,
    // requestFlow,
  ]);

  // Init
  useEffect(() => {
    // Open loading
    openLoadingSpinner();

    if (!isAuth) {
      return;
    }

    setInitPage(true);

    // Detail
    Promise.all([
      id ? API.getDetail(id) : null,
      KBN.getPriority(),
      KBN.getDepartmentList(),
      KBN.getDeparmentByCategoryDropDown(),
    ])
      .then((res) => {
        let d1 = res[0] ? res[0].data : {};
        let d2 = res[1] ? res[1].data.data : [];
        let d3 = res[2] ? res[2].data.data : [];
        let d4 = res[3] ? res[3].data.data : [];

        // Data detail
        if (id) {
          setddlPriority({
            ...ddlPriority,
            options: d2,
            value: d1.priority,
            label: d1.priorityValue,
          });

          setddlDepartment({
            ...ddlDepartment,
            options: d3,
            value: d1.departmentId,
            label: d1.departmentName,
          });

          setDdlCategoryType({
            ...ddlCategoryType,
            options: d4,
            label: d1.categoryType,
            value: d1.categoryType,
          });

          setDdlCategory({
            ...ddlCategory,
            label: d1.categoryName,
            value: d1.categoryId,
          });

          getCategoryByType(d1.categoryType, d1.categoryId, d1.categoryName);

          // Detail - 0
          showDetail(d1);
        } else {
          // DDL Priority default
          setddlPriority({
            ...ddlPriority,
            options: d2,
            value: "1",
            label: lang.LOW,
          });
          setddlDepartment({ ...ddlDepartment, options: d3 });
          setDdlCategoryType({ ...ddlCategoryType, options: d4 });
        }
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setTimeout(() => {
          setInitPage(false);
          closeLoadingSpinner();
        }, 500); // waiting for setup
      });
  }, [isAuth]);

  const showDetail = (data) => {
    //Group1
    settxtId({ ...txtId, value: data.id });
    settxtStatus({ ...txtStatus, value: data.status });
    settxtStatusText({ ...txtStatusText, value: data.statusValue });
    settxtStep({ ...txtStep, value: data.step });
    settxtRequester({ ...txtRequester, value: data.displayName });
    settxtContent({ ...txtContent, value: data.description });
    settxtTitle({ ...txtTitle, value: data.title });

    setRequestedDateDisplay({
      ...requestedDateDisplay,
      value: data.requestedDateDisplay,
    });

    let expectedCompleteDate = data.expectedCompleteDate
      ? moment.unix(data.expectedCompleteDate)
      : moment(new Date());
    setExpectedCompleteDate({
      ...expectedCompleteDate,
      value: expectedCompleteDate,
    });
    setExpectedCompleteDateDisplay({
      ...expectedCompleteDateDisplay,
      value: data.expectedCompleteDateDisplay,
    });

    // Popup attach
    data.listRequestAttachment = data.listRequestAttachment.map((v) => {
      let row = {};
      row["ItemOrder"] = v.itemOrder; // Key
      row["FileName"] = getNameFile(v.fileName);
      row["DotName"] = getDotFile(v.fileName);
      row["Description"] = v.description;
      row["attachmentUrl"] = v.attachmentUrl;
      return row;
    });
    indexFile = data.listRequestAttachment.length + 1;
    setTblAttach(data.listRequestAttachment);

    // Request flow
    let indexType = {};
    data.listRequestFlow = data.listRequestFlow.map((v, i) => {
      const userType = v.userType;
      let userLabel = v.userLabel;

      indexType[userType] = indexType[userType] ? indexType[userType] + 1 : 1;
      let userTypeIndex = STATUS.FLOW_USER_TYPE[userType] + indexType[userType];

      let row = {};
      row["uuid"] = uuidv4();
      row["Id"] = v.id;
      row["StepOrder"] = v.stepOrder;
      row["CurrentStep"] = data.step;
      row["UserLabel"] = userLabel;
      row["UserTypeIndex"] = userTypeIndex;
      row["UserType"] = v.userType;
      row["UserName"] = v.userName;
      row["UserNameDisplay"] = v.userNameDisplay;
      row["Remark"] = v.remark;
      row["Status"] = v.status;
      row["UpdateDatetimeDisplay"] = v.updateDatetimeDisplay;
      row["Element"] = { ...new classText(), users: [v.userName] };
      row["Required"] = !(userType === KEY.USER_TYPE_WORKER);

      let colorStatus = v.status;
      if (v.stepOrder === data.step) {
        colorStatus = "5";
      }
      const colorIndex = STATUS.STATUS_LIST.findIndex(
        (item) => item.key === colorStatus
      );

      if (colorIndex !== -1) {
        const colorCode = STATUS.STATUS_LIST[colorIndex]["color"];
        row["PanelColor"] = colorCode;
      }

      if (v.stepOrder === data.step + 1) {
        row["Required"] = true;
        if (v.userName === "") {
          setErrorMessage(
            "※" +
              format(
                COMMON_CONSTANT.WF_E002,
                userTypeIndex + "(" + userLabel + ")"
              )
          );
        }
      }

      if (!isAdmin && userType !== KEY.USER_TYPE_WORKER) {
        row["ViewMode"] = true;
      }

      return row;
    });

    // Not get status of flow in clone mode
    if (modeClone || history.location.pathname.includes("/CloneRequest")) {
      setRequestFlow(
        updateIndexFlow(
          data.listRequestFlow.map((item, i) => {
            return {
              uuid: item.uuid,
              Id: item.Id,
              StepOrder: item.StepOrder,
              UserType: item.UserType,
              UserTypeIndex: item.UserTypeIndex,
              UserLabel: item.UserLabel,
              UserName: item.UserName,
              Element: item.Element,
              CanDelete: item.CanDelete,
              Required: !(item.UserType == KEY.USER_TYPE_WORKER),
            };
          })
        )
      );
    } else {
      setRequestFlow(data.listRequestFlow);
    }
    setBackUpRequestFlow(getUserDataFromList(data.listRequestFlow));
  };

  const getFlowTemplateByCategory = (categoryValue) => {
    CategoryAPI.getFlowTemplateByCategory({ Category: categoryValue })
      .then((res) => {
        const data = res.data;
        let flowTemplate = null;
        if (!data || !data.flowTemplate) {
          flowTemplate = initFlow;
        } else {
          flowTemplate = data.flowTemplate;
        }
        // Clear old state
        setRequestFlow([]);

        var customFlow = false;
        if (categoryValue === COMMON_CONSTANT.OTHER) {
          customFlow = true;
        }
        setActiveEditFlow(customFlow);

        // Render new state
        renderFlow(flowTemplate, customFlow);
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  const getCategoryByType = (value, categoryId, categoryName) => {
    KBN.getCategoryDropDownGroup({ Type: value })
      .then((res) => {
        let options = res ? res.data.data : [];
        setDdlCategory({
          ...ddlCategory,
          options: options,
          label: categoryName,
          value: categoryId,
        });
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  // Popup common
  const showConfirmMessage = (
    msg,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: msg,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  //Save function: 1
  function confirmSaveData() {
    if (validateSave()) {
      showConfirmMessage(COMMON_CONSTANT.WF_C008, function () {
        openLoadingSpinner();
        // Create Form data
        const formData = new FormData();

        // Create Object
        let formInput = {
          Title: txtTitle.value,
          CategoryId: ddlCategory.value,
          DepartmentId: ddlDepartment.value,
          ExpectedCompleteDate: moment(expectedCompleteDate.value).unix(),
          Priority: ddlPriority.value,
          Description: txtContent.value,
        };

        // FILE
        if (tblAttach.length > 0) {
          // Add to obj
          formInput["ListRequestAttachment"] = tblAttach.map((v) => {
            v["FileName"] = v.FileName + "." + v.DotName; // Full file name
            return v;
          });
          // Add list file to FormDATA
          tblAttach.forEach((v, i) => {
            formData.append(`file[${i}]`, v.file);
          });
        }

        // Implement User
        if (requestFlow.length > 0) {
          // Add to obj
          formInput["ListRequestFlow"] = getUserDataFromList(requestFlow);
        }

        formData.append("data", JSON.stringify(formInput));

        if (modeClone) {
          id = undefined;
        }

        // Sent data to Server
        API.save(formData, id)
          .then((res) => {
            if (statusRes(res)) {
              FLAG_CHANGE_DATA = false;
              dispatch(setIsUpdated(FLAG_CHANGE_DATA));
              // Success
              showMessage(KEY.INFO, COMMON_CONSTANT.WF_C003, backToSearch);
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            showMessage(KEY.ERROR, MSG_CATCH());
          })
          .then(() => {
            closeLoadingSpinner();
          });
      });
    }
    return false;
  }

  function validateSave() {
    let flagErr = false,
      elmFocus;

    // txtContent = {
    //   ...txtContent,
    //   value: txtContent.value.trim(),
    //   error: false,
    // };

    txtTitle = {
      ...txtTitle,
      value: txtTitle.value.trim(),
      error: false,
    };

    ddlCategory = { ...ddlCategory, error: false };
    ddlCategoryType = { ...ddlCategoryType, error: false };
    ddlDepartment = { ...ddlDepartment, error: false };
    ddlPriority = { ...ddlPriority, error: false };

    expectedCompleteDate = {
      ...expectedCompleteDate,
      value: expectedCompleteDate.value,
      error: false,
    };

    requestFlow = requestFlow.map((item, i) => {
      return {
        ...item,
        error: false,
      };
    });

    if (requestFlow.length > 0) {
      requestFlow = requestFlow.map((item, i) => {
        if (
          item.Required &&
          (item.UserName.length === 0 || item.Element.message.length > 0)
        ) {
          flagErr = true;
          if (!elmFocus) {
            elmFocus = item.ElementRef;
          }
          return {
            ...item,
            error: true,
          };
        } else {
          return item;
        }
      });
    }

    if (!ddlPriority.value || ddlPriority.value === "") {
      flagErr = true;
      // Border red
      ddlPriority = { ...ddlPriority, error: true };
      elmFocus = ddlPriorityEl;
    }

    if (
      expectedCompleteDate.value === null ||
      expectedCompleteDate.value === ""
    ) {
      flagErr = true;
      // Border red
      expectedCompleteDate = { ...expectedCompleteDate, error: true };
      elmFocus = expectedCompleteDateEl;
    }

    // if (txtContent.value.length === 0) {
    //   flagErr = true;
    //   // Border red
    //   txtContent = { ...txtContent, error: true };
    //   elmFocus = txtContentEl;
    // }

    if (!ddlDepartment.value || ddlDepartment.value === "") {
      flagErr = true;
      // Border red
      ddlDepartment = { ...ddlDepartment, error: true };
      elmFocus = ddlDepartmentEl;
    }

    if (!ddlCategory.label || ddlCategory.label === "") {
      flagErr = true;
      // Border red
      ddlCategory = { ...ddlCategory, error: true };
      elmFocus = ddlCategoryEl;
    }

    if (!ddlCategoryType.label || ddlCategoryType.label === "") {
      flagErr = true;
      // Border red
      ddlCategoryType = { ...ddlCategoryType, error: true };
      elmFocus = ddlCategoryTypeEl;
    }

    if (txtTitle.value.length === 0) {
      flagErr = true;
      // Border red
      txtTitle = { ...txtTitle, error: true };
      elmFocus = txtTitleEl;
    }

    // Focus item error
    elmFocus && elmFocus.current.focus();

    // Update state
    setDdlCategory(ddlCategory);
    setDdlCategoryType(ddlCategoryType);
    // settxtContent(txtContent);
    settxtTitle(txtTitle);
    setddlDepartment(ddlDepartment);
    setddlPriority(ddlPriority);
    setExpectedCompleteDate(expectedCompleteDate);

    setRequestFlow(requestFlow);

    if (!flagErr) {
      let require = false;
      let exceed = false;
      let min = false;

      // Show message err
      if (require || min || exceed) {
        const msg = [];
        const firstLine = "・";
        require && msg.push(firstLine + COMMON_CONSTANT.WF_E031);
        exceed && msg.push(firstLine + COMMON_CONSTANT.WF_E032);
        min && msg.push(firstLine + COMMON_CONSTANT.WF_E034);

        // Set fail
        flagErr = true;
        // Show message
        showMessage(
          KEY.ERROR,
          <div className="white-space">{msg.join("\n")}</div>
        );
      }
    }

    return !flagErr;
  }

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      dispatch(setIsUpdated(FLAG_CHANGE_DATA));
      showConfirmMessage(
        COMMON_CONSTANT.WF_C018,
        function () {
          backToSearch();
        },
        function () {
          FLAG_CHANGE_DATA = true;
          dispatch(setIsUpdated(FLAG_CHANGE_DATA));
        }
      );
    }
  }

  function readAttachFileUrl(ev) {
    let el = ev.target;
    if (el.files && el.files[0]) {
      if (el.files[0].size > 102400000) {
        setFileAttachErrorMessage(COMMON_CONSTANT.WF_E001);
        el.value = ""; // Clear file
        return;
      }

      formDataAttach.append(indexFile++, el.files[0]);
      el.value = ""; // Clear file
      // Render
      renderTableAttach();
    }
  }

  function renderTableAttach() {
    let list = [...tblAttach];
    let id = indexFile - 1;
    let file = formDataAttach.get(id);
    let row = {};
    row["ItemOrder"] = id; // Key
    row["FileName"] = getNameFile(file.name);
    row["DotName"] = getDotFile(file.name);
    row["Description"] = "";
    row["file"] = file; // File
    list.push(row);
    setTblAttach(list);
  }
  // End: Tbl Attach

  useEffect(() => {
    if (action === undefined) return;
    switch (action.action) {
      case KEY.ADD:
        let result = [];
        if (action.index == requestFlow.length) {
          result = [
            ...requestFlow,
            {
              uuid: uuidv4(),
              Element: new classText(),
              UserType: action.type,
              CanDelete: KEY.FLAG_ONE,
              UserName: "",
            },
          ];
          if (listWorker.length == 0 && action.type == KEY.USER_TYPE_WORKER) {
            result = [
              ...result,
              {
                uuid: uuidv4(),
                Element: new classText(),
                UserType: KEY.USER_TYPE_CONFIRM_APPROVER,
                CanDelete: KEY.FLAG_ZERO,
                UserName: "",
              },
            ];
          }
        } else {
          const insert = (arr, index, newItem) => [
            ...arr.slice(0, index),
            newItem,
            ...arr.slice(index),
          ];
          result = insert(requestFlow, action.index, {
            uuid: uuidv4(),
            Element: new classText(),
            UserType: action.type,
            CanDelete: KEY.FLAG_ONE,
            UserName: "",
          });
        }
        setRequestFlow(updateIndexFlow(result));
        break;

      case KEY.UPDATE:
        const newRequestFlow = requestFlow.map((item, i) => {
          if (i === action.index) {
            return action.data;
          } else {
            if (action.data.UserTypeIndex == item.UserNameRef) {
              // item.UserName = action.data.UserName;
              // item.Element = { ...new classText(), users: [action.data.UserName] }

              item = {
                ...item,
                Element: action.data.Element,
                ElementRef: action.data.ElementRef,
                UserName: action.data.UserName,
              };
            }
            return item;
          }
        });
        setRequestFlow(newRequestFlow);

        break;

      case KEY.DELETE:
        let newRequestFlowDelete = [...requestFlow];
        requestFlow.map((item, i) => {
          if (action.index == item.uuid) {
            newRequestFlowDelete.splice(i, 1);
          }
        });
        const listWorkerDelete = newRequestFlowDelete.filter(
          (e) => e.UserType == KEY.USER_TYPE_WORKER
        );
        if (listWorkerDelete.length == 0) {
          newRequestFlowDelete = newRequestFlowDelete.filter(
            (e) => e.UserType == KEY.USER_TYPE_APPROVER
          );
        }
        setRequestFlow(updateIndexFlow(newRequestFlowDelete));
        break;

      default:
        setAction(undefined);
        break;
    }
  }, [action]);

  function renderFlow(flowTemplate, canDelete) {
    const tempArr = flowTemplate.split(",");
    let indexType = {};
    const listFlow = tempArr.map((userTypeTemplate, i) => {
      let userLabel = "",
        userType,
        userName = "",
        userNameRef = "";

      if (userTypeTemplate.indexOf(":") != -1) {
        let arrTemplate = userTypeTemplate.split(":");
        if (arrTemplate.length == 2) {
          userType = arrTemplate[0];
          userName = arrTemplate[1];
        }
        if (arrTemplate.length == 3) {
          userLabel = arrTemplate[0];
          userType = arrTemplate[1];
          userName = arrTemplate[2];
        }
        if (arrTemplate.length == 4) {
          userLabel = arrTemplate[0];
          userType = arrTemplate[1];
          userName = arrTemplate[2];
          userNameRef = arrTemplate[3];

          // Set requester
          if (userNameRef == lang.REQUESTER) {
            userName = username;
          }
        }
      } else {
        userType = userTypeTemplate;
      }

      indexType[userType] = indexType[userType] ? indexType[userType] + 1 : 1;
      let userTypeIndex = STATUS.FLOW_USER_TYPE[userType] + indexType[userType];

      let row = {
        uuid: uuidv4(),
        Element: { ...new classText(), users: [userName] },
        StepOrder: i + 1,
        CanDelete: canDelete && (i == 0 ? KEY.FLAG_ZERO : KEY.FLAG_ONE),
        Required: !(userType == KEY.USER_TYPE_WORKER),
        UserType: userType,
        UserTypeIndex: userTypeIndex,
        UserLabel: userLabel,
        UserName: userName,
        UserNameRef: userNameRef,
      };
      return row;
    });
    setRequestFlow(listFlow);
  }

  function updateIndexFlow(flows) {
    let indexType = {};
    return flows.map((item, index) => {
      const userType = item.UserType;
      let userLabel = item.UserLabel;
      indexType[userType] = indexType[userType] ? indexType[userType] + 1 : 1;
      const userTypeIndex =
        STATUS.FLOW_USER_TYPE[userType] + indexType[userType];
      return {
        ...item,
        StepOrder: index + 1,
        UserType: userType,
        UserTypeIndex: userTypeIndex,
        UserLabel: userLabel,
        CanDelete:
          activeEditFlow && (index == 0 ? KEY.FLAG_ZERO : KEY.FLAG_ONE),
      };
    });
  }

  function getUserDataFromList(flows) {
    return flows.map((item, i) => {
      return {
        Id: item.Id,
        StepOrder: item.StepOrder,
        UserType: item.UserType,
        UserTypeIndex: item.UserTypeIndex,
        UserLabel: item.UserLabel,
        UserName: item.UserName,
      };
    });
  }

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={id ? "作業報告編集" : "依頼作成"} />
      {/* Group 1 */}
      <GroupItem name="依頼情報" center>
        {!isEmpty(errorMessage) && (
          <>
            <div className="red fs14 f-bold mb10">{errorMessage}</div>
          </>
        )}

        <BreakLine />
        {modeUpdateFlow && !isEmpty(txtId.value) && (
          <>
            <RowInput name={lang.WORKFLOW_NUMBER} box>
              {txtId.value}
            </RowInput>
            <BreakLine />
          </>
        )}
        <RowInput box name={lang.REQUEST_SUBJECT} required={modeEdit}>
          {modeEdit ? (
            <>
              <Input
                value={txtTitle.value}
                ref={txtTitleEl}
                className={txtTitle.error ? `${STYLE.BORDER_RED}` : ""}
                maxLength={255}
                onChange={(e) =>
                  settxtTitle({
                    ...txtTitle,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <>
              <div className="white-space"> {txtTitle.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput
          box
          name={lang.REQUEST_DEPARTMENT_OF_CATEGORY}
          required={modeEdit}
        >
          {modeEdit ? (
            <>
              <Select
                showSearch
                ref={ddlCategoryTypeEl}
                value={ddlCategoryType.label}
                className={
                  ddlCategoryType.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  getCategoryByType(value, "", "");
                  setDdlCategoryType({
                    ...ddlCategoryType,
                    value: option.key,
                    label: option.value,
                  });
                }}
                // optionFilterProp="children"
                options={ddlCategoryType.options}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.trim().toUpperCase()) !== -1
                }
              >
                {renderOption(ddlCategoryType.options)}
              </Select>
            </>
          ) : (
            <>
              <div className="white-space"> {ddlCategoryType.label}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.REQUEST_CATEGORY} required={modeEdit}>
          {modeEdit ? (
            <>
              <Select
                showSearch
                ref={ddlCategoryEl}
                value={ddlCategory.label}
                className={
                  ddlCategory.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  getFlowTemplateByCategory(option.label);
                  setDdlCategory({
                    ...ddlCategory,
                    value: option.value,
                    label: option.label,
                  });
                }}
                options={ddlCategory.options}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.trim().toUpperCase()) !== -1
                }
              >
                {renderOption(ddlCategory.options)}
              </Select>
            </>
          ) : (
            <>
              <div className="white-space"> {ddlCategory.label}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        {!modeClone && !isEmpty(txtStatusText.value) && (
          <>
            <RowInput name={lang.STATUS} box>
              <Paragraph
                ellipsis={{ rows: 1 }}
                className="textStatus"
                style={{
                  width: "min-content",
                  background: STATUS.STATUS_COLOR[txtStatus.value],
                  // background: STATUS.STATUS_COLOR[row.status] + "1A",
                  // border: "1px solid " + STATUS.STATUS_COLOR[row.status]
                  fontWeight: "bold",
                }}
              >
                {txtStatusText.value}
              </Paragraph>
            </RowInput>
            <BreakLine />
          </>
        )}

        <RowInput box name={lang.REQUEST_DEPARTMENT} required={modeEdit}>
          {modeEdit ? (
            <>
              <Select
                showSearch
                ref={ddlDepartmentEl}
                value={ddlDepartment.label}
                className={
                  ddlDepartment.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  setddlDepartment({
                    ...ddlDepartment,
                    value: option.value,
                    label: option.label,
                  });
                }}
                // optionFilterProp="children"
                options={ddlDepartment.options}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.trim().toUpperCase()) !== -1
                }
              >
                {renderOption(ddlDepartment.options)}
              </Select>
            </>
          ) : (
            <>
              <div className="white-space"> {ddlDepartment.label}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.REQUEST_CONTENT}>
          {modeEdit ? (
            <>
              <TextArea
                ref={txtContentEl}
                className={txtContent.error ? STYLE.BORDER_RED : ""}
                value={txtContent.value}
                onChange={(v) => {
                  settxtContent({
                    ...txtContent,
                    value: v.target.value,
                  });
                }}
                rows={4}
              />
            </>
          ) : (
            <>
              <div className="white-space"> {txtContent.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        {!modeClone && !isEmpty(txtRequester.value) && (
          <>
            <RowInput name={lang.REQUESTER} box>
              <div className="white-space">{txtRequester.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}

        {!modeClone && !isEmpty(requestedDateDisplay.value) && (
          <>
            <RowInput box name={lang.REQUESTED_DATE} required={modeEdit}>
              <div className="white-space"> {requestedDateDisplay.value}</div>
            </RowInput>
            <BreakLine />
          </>
        )}

        <RowInput box name={lang.REQUEST_DUE_DATE} required={modeEdit}>
          {!modeEdit ? (
            <>
              <div className="white-space">
                {expectedCompleteDateDisplay.value}
              </div>
            </>
          ) : (
            <>
              <DatePicker
                format={expectedCompleteDate.format || KEY.DATE_DEFAULT}
                ref={expectedCompleteDateEl}
                value={expectedCompleteDate.value}
                picker={expectedCompleteDate.picker}
                placeholder={expectedCompleteDate.format || KEY.DATE_DEFAULT}
                disabledDate={(d) =>
                  !d || d.isBefore(new Date().setDate(new Date().getDate() - 1))
                }
                className={
                  "w100 " + (expectedCompleteDate.error ? STYLE.BORDER_RED : "")
                }
                onChange={(v) => {
                  setExpectedCompleteDate({
                    ...expectedCompleteDate,
                    value: v,
                  });
                }}
                {...expectedCompleteDate.input}
              />
            </>
          )}
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.PRIORITY} required={modeEdit}>
          {!modeEdit ? (
            <>
              <div className="white-space"> {ddlPriority.label}</div>
            </>
          ) : (
            <>
              <Select
                ref={ddlPriorityEl}
                value={ddlPriority.label}
                options={ddlPriority.options}
                className={
                  ddlPriority.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  setddlPriority({
                    ...ddlPriority,
                    value: option.key,
                    label: value,
                  });
                }}
              >
                {renderOption(ddlPriority.options)}
              </Select>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.ATTACH}>
          <>
            {modeEdit && (
              <>
                <div className="red  mb10">{lang.ATTACH_WARNING}</div>
                <input
                  type="file"
                  className="d-none"
                  // accept=".xls,.xlsx"
                  ref={fileEl}
                  onChange={readAttachFileUrl}
                />
                <Button
                  type="link"
                  onClick={() => {
                    setFileAttachErrorMessage("");
                    fileEl.current.click();
                  }}
                  className="buttonPC button--outline --todo-- wAuto"
                  style={{
                    backgroundColor: "#f7f9fc",
                    marginBottom: 10,
                    borderColor: "lightgray",
                  }}
                >
                  <ToTopOutlined />
                  {lang.ATTACH}
                </Button>

                <div className="red fs14 mb10">{fileAttachErrorMessage}</div>
              </>
            )}

            {tblAttach.length > 0 &&
              tblAttach.map((item, index) => {
                let urlFile = item.file
                  ? URL.createObjectURL(item.file)
                  : item.attachmentUrl;

                let fileName = item.file
                  ? item.file.name
                  : item.FileName + "." + item.DotName;

                return (
                  <Row
                    className="mb10"
                    key={index}
                    style={{ alignItems: "center" }}
                  >
                    {modeEdit && (
                      <>
                        <Col span={2}>
                          <Button
                            onClick={() => {
                              let list = [...tblAttach].filter(
                                (x) => x["ItemOrder"] !== item["ItemOrder"]
                              );
                              setTblAttach(list);
                            }}
                            className="btn-deletefile mr8"
                            style={{
                              background: "#f7f9fc",
                              minWidth: "unset",
                              height: 30,
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </>
                    )}
                    <Col>
                      <a href={urlFile} download={fileName} target="_blank">
                        {fileName}
                      </a>
                    </Col>
                  </Row>
                );
              })}
          </>
        </RowInput>
        <BreakLine />
      </GroupItem>

      <GroupItem name="依頼フロー" center>
        <Row>
          <Col span={8}>
            {!initPage && (
              <>
                <RequestFlow requester={displayName} flowObject={requestFlow} />
              </>
            )}
          </Col>
          <Col
            span={16}
            className={"pl20"}
            style={{ borderLeft: "1px solid #e6e6e6" }}
          >
            <Row>
              {!initPage &&
                //  id &&
                requestFlow.length > 0 &&
                requestFlow.map((item, index) => {
                  return (
                    <>
                      <UserPanel
                        key={index}
                        editMode
                        userName={item.UserName}
                        remark={item.Remark}
                        getUser={item.Element}
                        setUser={(data) => {
                          item["Element"] = data;
                          item["UserName"] =
                            data.users.length > 0 ? data.users[0] : "";
                          setAction({
                            action: KEY.UPDATE,
                            index: index,
                            data: item,
                          });
                        }}
                        className={
                          requestFlow[index].error ? STYLE.BORDER_RED : ""
                        }
                        flowObject={item}
                        setUserRef={(data) => {
                          item["ElementRef"] = data;
                        }}
                        onDelete={(data) => {
                          setAction({
                            action: KEY.DELETE,
                            index: item.uuid,
                          });
                        }}
                      />

                      {activeEditFlow && listApprove.length === index + 1 && (
                        <>
                          <Col span={24}>
                            <Row justify="end">
                              <Col style={{ display: "flex" }}>
                                <Button
                                  type="link"
                                  className="btnAddRecord mb15 mr15"
                                  onClick={() => {
                                    setAction({
                                      action: KEY.ADD,
                                      type: KEY.USER_TYPE_APPROVER,
                                      index: index + 1,
                                    });
                                  }}
                                >
                                  <AddIcon />
                                  {lang.ADD_APPROVER}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}

                      {activeEditFlow &&
                        listApprove.length + listWorker.length ===
                          index + 1 && (
                          <>
                            <Col span={24}>
                              <Row justify="end">
                                <Col style={{ display: "flex" }}>
                                  <Button
                                    type="link"
                                    className="btnAddRecord mb15 mr15"
                                    onClick={() => {
                                      setAction({
                                        action: KEY.ADD,
                                        type: KEY.USER_TYPE_WORKER,
                                        index: index + 1,
                                      });
                                    }}
                                  >
                                    <AddIcon />
                                    {lang.ADD_WORKER}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}

                      {activeEditFlow &&
                        listWorker.length > 0 &&
                        listApprove.length +
                          listWorker.length +
                          listConfirmApprove.length ===
                          index + 1 && (
                          <>
                            <Col span={24}>
                              <Row justify="end">
                                <Col style={{ display: "flex" }}>
                                  <Button
                                    type="link"
                                    className="btnAddRecord mb15 mr15"
                                    onClick={() => {
                                      setAction({
                                        action: KEY.ADD,
                                        type: KEY.USER_TYPE_CONFIRM_APPROVER,
                                        index: index + 1,
                                      });
                                    }}
                                  >
                                    <AddIcon />
                                    {lang.ADD_APPROVER}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}
                    </>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </GroupItem>

      <FooterPage>
        <Row justify="end">
          <Col>
            <Button
              className="buttonPC button--info wAuto ml15 mb5 mt5"
              onClick={() => {
                confirmSaveData();
              }}
            >
              <SaveOutlined />
              {modeCreate || modeClone
                ? COMMON_CONSTANT.SAVE
                : COMMON_CONSTANT.UPDATE}
            </Button>
            <Button
              className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
              onClick={cancelSaveData}
            >
              <CloseCircleOutlined />
              {lang.CANCEL}
            </Button>
          </Col>
        </Row>
      </FooterPage>
    </div>
  );
}

export default CreateRequest;
