import { combineReducers } from "redux";
import navBarReducer from "./navBar";
import loadingReducer from "./loading";
import confirmReducer from "./confirm";
import settingReducer from "./setting";
import authReducer from "./auth";
import sideBarReducer from "./sideBar";
import routerReducer from "./router";
import requestReducer from "./request";

const rootReducer = combineReducers({
  auth: authReducer,
  navBar: navBarReducer,
  loading: loadingReducer,
  confirm: confirmReducer,
  setting: settingReducer,
  sideBar: sideBarReducer,
  router: routerReducer,
  request: requestReducer,
});

export default rootReducer;
