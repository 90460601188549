import { intersection } from "lodash";
export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function checkMenu(arrMenu, r) {
  return arrMenu.filter(({ permission }) => {
    // if public route return true
    if (!permission) {
      return true;
      // if set permission !== array => return true
    } else if (!isArrayWithLength(permission)) {
      return true;
    } else {
      // if permission is included roles => return true
      return intersection(permission, [r]).length;
    }
  });
}

export function getAllowedRoutes(routes, roles) {
  // Filter menu parent
  let menuNew = checkMenu(routes, roles);
  // Filter menu child exist
  menuNew = menuNew.map((m) => {
    if (m.children) {
      m.children = checkMenu(m.children, roles);
    }
    return m;
  });
  return menuNew;
}
