/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { KEY, lang } from "../../constants/common.const";
import "./autoMail.scss";
import { Col, Row, Input, Button, Modal, Table } from "antd";
import { statusRes, MSG_CATCH, enterSearch } from "../../constants/utils";
import classPopup from "../../models/control/popup";
import API from "../../api/backend/user";
import { useMediaQuery } from "react-responsive";
import {
  UserAddOutlined,
  FileSearchOutlined,
  SearchOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import classText from "../../models/control/text";

function AutoMail({
  get = new classText(),
  set,
  setRef,
  className,
  disabled = false,
  typeSearch = null,
  placeholder,
  onMouseOver = null,
  onMouseOut = null,
}) {
  let input = useRef(null);
  let inputPopup = useRef(null);
  // const isPC = useMediaQuery({
  //   query: KEY.LAPTOP_WIDTH,
  // });

  const isPC = true;

  // Popup picker
  const [txtKey, setTxtKey] = useState(new classText());
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [listSelectedUser, setListSelectedUser] = useState([]);
  const [isMultiUser, setIsMultiUser] = useState(false);

  let existErr = false;

  let iStartEl;
  let iStart;

  // Popup
  const [popupWarning, setPopupWarning] = useState(new classPopup());
  const [popupError, setPopupError] = useState(new classPopup());
  const showMessageError = (msg) => {
    setPopupError({ ...popupError, show: true, title: msg });
  };

  useEffect(() => {
    setRef && setRef(input); // For refer
  }, [setRef]);

  useEffect(() => {
    setRef && setRef(input); // For refer
    const a = async () => {
      if (get.users && get.users.length > 0) {
        // Detail
        input.current.innerHTML = get.users.join("; ");
        await onMap(true);
      }
    };
    a();
  }, []);

  // Button 1
  // auto complete - Step 1
  const onMap = async (init = false) => {
    existErr = false;
    let value = input.current.innerHTML
      .replace(/;;/g, ";") // Duplicate
      .replace(/&nbsp;/g, " ")
      .replace(/&lt;/g, "<")
      .replace(/&amp;/g, "&")
      .replace(/<br>/g, "") // Fix safari
      .replace(/<span /g, ";<span ") // case input between
      .replace(/&gt;/g, ">"); // HTML

    let listElem = value.split(";");

    // Check max length 256
    let maxLength = false;
    listElem.forEach((v) => {
      if (v.length > 256) {
        maxLength = true;
      }
    });

    if (maxLength) {
      alert("アイテムは 256 文字以下にしてください。");
    } else {
      // Remove element mail
      listElem = listElem.map((v) => v.trim()).filter((v) => v.length > 0);

      // Get user
      await Promise.all(
        listElem
          .filter((v) => v.indexOf(`<span `) === -1)
          .map((v) =>
            API.getUser({
              UserName: v.indexOf('@') !== -1 ? v.split('@')[0]: v,
              type: typeSearch,
            })
          )
      )
        .then((res) => {

          if (!isMultiUser) {
            if (listElem.length > 1) {
              listElem.splice(0, listElem.length - 1);
            }
          }
          
          listElem = listElem.reduce((listBefore, v) => {
            let userElm = {
              value: "",
              html: "",
              error: false,
              email: "",
              displayName: "",
            };

            if (v.indexOf(`<span `) >= 0) {
              // Exist
              if (v.indexOf(`autoMail-err`) >= 0) {
                existErr = true;
              }
              // Remap value
              get.list.forEach((e) => {
                if (
                  e.html === v.replace("contenteditable", "contentEditable")
                ) {
                  userElm = e;
                }
              });
            } else {
              let keyword = v.indexOf('@') !== -1 ? v.split('@')[0]: v;
              // User info
              const objU = res.filter(
                (oU) => String(oU.data.userName).toLowerCase() === keyword.toLowerCase()  || 
                        String(oU.data.shiId).toLowerCase() === keyword.toLowerCase()
              )[0];
              userElm = getUserInfo(v, objU);
            }
            return [...listBefore, userElm];
          }, []);

          // Update
          set({
            ...get,
            error: existErr,
            message: existErr
              ? "完全一致はありませんでした。解決しなかったアイテムをクリックして、他のオプションを選択してください。"
              : "",
            users: listElem.map((v) => v.value),
            list: listElem,
            init: init,
          });
        })
        .catch((err) => {
          showMessageError(MSG_CATCH());
        });
    }
  };
  // Render inputPopup
  useEffect(() => {
    let list = [...get.list];
    // Update div
    input.current.innerHTML =
      list
        .map((v) => {
          if (v.error) {
            v.html = `<span contentEditable="false" class="autoMail-err">${v.value}</span>`;
          } else {
            v.html = `${'<span contentEditable="false" class="autoMail-user">'}${
              v.displayName
            }</span>`;
          }
          return v.html;
        })
        .join("; ") + (list.length > 0 ? `;&nbsp;` : "");
  }, [get.list]);
  // API get info - Step 2
  const getUserInfo = (v, res) => {
    let userElm = {
      value: v,
      html: "",
      error: false,
      email: "",
    };

    try {
      if (statusRes(res)) {
        userElm.displayName = res.data.displayName;
        userElm.email = res.data.email;
        userElm.value = res.data.userName;
      } else {
        throw new Error();
      }
    } catch (err) {
      if (window.navigator.onLine) {
        userElm.error = true;
        existErr = true;
      } else {
        showMessageError(MSG_CATCH());
      }
    }
    return userElm;
  };

  // Button 2: POPUP
  const columnsMobile = [
    {
      title: "表示名",
      dataIndex: "displayName",
      width: "100px",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "電子メール アドレス",
      width: "230px",
      dataIndex: "email",
      render: (text, record) => (
        <div style={{ wordWrap: "break-all", wordBreak: "break-all" }}>
          {text}
        </div>
      ),
    },
  ];
  const columnsPC = [
    ...columnsMobile,
    {
      title: "ユーザ名",
      dataIndex: "userName",
      width: "100px",
    },
    {
      title: "SHI-ID",
      dataIndex: "shiId",
      width: "100px",
    },
  ];
  // Open search
  const openSearchEmployee = () => {
    setPopupWarning({
      ...popupWarning,
      show: true,
    });
  };
  const onSearchEmployee = () => {
    setLoader(true);

   

    API.searchUser({
      keyword: txtKey.value.trim(),
      type: typeSearch,
    }).then((res) => {
      setSelectedData([]);
      setDataSource(res.data);
      setLoader(false);
    });
  };
  const onSelectUser = (selectedData, selectedRows) => {
    setSelectedData(selectedRows);
  };

  useEffect(() => {
    if (selectedData.length > 0) {
      onFillGridToInputPopup();
    }
  }, [selectedData]);

  const onFillPopupToScreen = () => {
    if (isMultiUser) {
      set({
        ...get,
        list: [
          ...get.list,
          ...listSelectedUser.map((v) => {
            v["value"] = v.userName;
            return v;
          }),
        ],
        users: [...get.users, ...listSelectedUser.map((v) => v.userName)],
      });
    } else {
      set({
        ...get,
        list: [
          ...listSelectedUser.map((v) => {
            v["value"] = v.userName;
            return v;
          }),
        ],
        users: [...listSelectedUser.map((v) => v.userName)],
      });
    }

    setPopupWarning({
      ...popupWarning,
      show: false,
    });
    setDataSource([]);
    setSelectedData([]);
    setListSelectedUser([]);
    setTxtKey(new classText());
  };

  // FILL inputPopup
  const onFillGridToInputPopup = () => {
    let listSelect = [...selectedData];

    const seen = new Set();
    let filteredArr = listSelect.filter((el) => {
      const duplicate = seen.has(el.userName);
      seen.add(el.userName);
      return !duplicate;
    });

    setListSelectedUser(filteredArr);
  };
  const onKeyDownPopup = (e) => {
    if (e.keyCode !== 8) {
      // Only Delete
      e.preventDefault();
      return false;
    }
  };
  const onBlurInputPopup = () => {
    let txt = inputPopup.current.innerText;
    let listNew = [...listSelectedUser];
    txt = txt.split(";").map((v) => {
      return v.trim();
    });

    listNew = listNew.filter((v) => {
      return txt.indexOf(v.displayName) >= 0;
    });

    // Update inputPopup
    setListSelectedUser(listNew);
  };
  // Render inputPopup
  useEffect(() => {
    let list = [...listSelectedUser].map((v) => {
      return {
        value: v.userName,
        html: `${'<span contentEditable="false" class="autoMail-user">'}${
          v.displayName
        }</span>`,
        error: false,
        email: v.email,
        displayName: v.displayName,
      };
    });

    // Update div
    if (inputPopup.current) {
      inputPopup.current.innerHTML =
        list
          .map((v) => {
            return v.html;
          })
          .join("; ") + (list.length > 0 ? `;&nbsp;` : "");
    }
  }, [listSelectedUser]);

  // Get Select
  const getSelect = (nodeRef) => {
    const sel = window.getSelection();
    const listChild = Array.prototype.slice.call(nodeRef.current.childNodes);
    try {
      let elStart = sel.getRangeAt(0).startContainer;
      iStartEl = listChild.indexOf(elStart);
      iStart = sel.getRangeAt(0).startOffset;

      if (elStart.nodeType === 3) {
        // Add
        iStart =
          iStart + (iStartEl > 0 ? iStartEl + 1 : 0) - elStart.length + 2;
      } else {
        // debugger;
      }
    } catch (err) {
      iStartEl = -1;
    }
  };
  // Re select
  const reSelect = (nodeRef) => {
    // Re select
    setTimeout(() => {
      // var listChild = nodeRef.current.childNodes;
      if (iStartEl !== -1) {
        // let elStart = listChild[iStartEl];
        const range = document.createRange();
        const sel = window.getSelection();

        // range.setStart(elStart, iStart);
        try {
          range.setStart(nodeRef.current, iStart);
          range.collapse(true);

          sel.removeAllRanges();
          sel.addRange(range);
        } catch (err) {}
      }
    }, 10);
  };

  // RENDER
  return (
    <div className="autoMail " style={{ position: "relative" }}>
      <Row>
        {/* Input */}
        <div
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          ref={input}
          contentEditable={!disabled}
          placeholder={placeholder}
          className={"ant-input autoMail-input " + className}
          onKeyDown={async (e) => {
            if (e.keyCode === 13) {
              // Enter
              e.preventDefault();
              getSelect(input);
              await onMap();
              reSelect(input);
              return false;
            } else if (e.keyCode === 8) {
              // Del
              let lOld = input.current.innerText.length;
              setTimeout(async () => {
                let lNew = input.current.innerText.length;
                if (lOld - lNew > 1) {
                  const sel = window.getSelection();
                  const listChild = Array.prototype.slice.call(
                    input.current.childNodes
                  );
                  let elStart = sel.getRangeAt(0).startContainer;
                  iStartEl = listChild.indexOf(elStart);
                  iStart = sel.getRangeAt(0).startOffset;

                  await onMap();

                  // Re-select
                  setTimeout(() => {
                    const listChild = input.current.childNodes;
                    const elStart = listChild[iStartEl];
                    if (elStart) {
                      const range = document.createRange();
                      const sel = window.getSelection();

                      range.setStart(elStart, iStart);
                      range.collapse(true);

                      sel.removeAllRanges();
                      sel.addRange(range);
                    }
                  }, 10);
                }
              }, 10);
            } else {
              // await onMap();
            }
          }}
          onPaste={(e, el) => {
            // Only text
            setTimeout(function () {
              input.current.innerText = input.current.innerText.toString();
            }, 10);
          }}
        ></div>
        {/* Button */}
        <div className="right autoMail-button">
          {!disabled && (
            <>
              {" "}
              <UserAddOutlined className="mr10" onClick={onMap} />
              <FileSearchOutlined onClick={openSearchEmployee} />
            </>
          )}
        </div>
      </Row>

      {/* Message */}
      {get.message.length > 0 && <div className="red">{get.message}</div>}

      {/* Search employee */}
      <Modal
        centered
        width={750}
        closeIcon={<></>}
        className="modal-msg autoMail-popup"
        title="ユーザー の選択"
        visible={popupWarning.show}
        footer={
          <>
            {/* <div className="left">
              <Row className="align-center pt8  pb8">
                <Col span={24} className="left">
                  <Button
                    className="buttonPC button--info wAuto mr5"
                    onClick={onFillGridToInputPopup}
                    disabled={selectedData.length === 0}
                  >
                    {`追加 ➞`}
                  </Button>
                  <div
                    style={{
                      width: "calc(100% - 102px)",
                    }}
                    id="inputPopup"
                    ref={inputPopup}
                    contentEditable={true}
                    className="ant-input d-inline autoMail-listSelect"
                    onKeyDown={onKeyDownPopup}
                    onBlur={onBlurInputPopup}
                  ></div>
                </Col>
              </Row>
            </div> */}
            <div className="right">
              <Button
                key={0}
                onClick={onFillPopupToScreen}
                className="buttonPC button--info wAuto"
                disabled={listSelectedUser.length === 0}
              >
                {lang.OK}
              </Button>
              <Button
                key={1}
                className="buttonPC button--outline --todo-- wAuto"
                onClick={() => {
                  setPopupWarning({ ...popupWarning, show: false });
                }}
              >
                <CloseCircleOutlined />
                {lang.CANCEL}
              </Button>
            </div>
          </>
        }
      >
        <Row gutter={[16, 16]} className="align-center pt8 pl8 pr8 pb8">
          <Col span={24}>
            <Input
              maxLength={255}
              style={{
                width: "calc(100% - 102px)",
              }}
              placeholder={lang.SEARCH}
              value={txtKey.value}
              onChange={(e) => {
                setTxtKey({ ...txtKey, value: e.target.value });
              }}
              {...enterSearch(onSearchEmployee)}
            />
            <Button
              className="buttonPC button--info wAuto ml5"
              onClick={onSearchEmployee}
              disabled={txtKey.value.trim().length === 0}
            >
              <SearchOutlined className="" />
              検索
            </Button>
          </Col>
        </Row>
        <Table
          className=" pl8 pr8 "
          rowSelection={{
            type: isMultiUser ? "checkbox" : "radio",
            selectedRowKeys: selectedData.map((v) => v.userName),
            onChange: onSelectUser,
            getCheckboxProps: (record) => {
              // let dis = false;
              // listSelectedUser.forEach(v => {
              //     if (v.userName == record.userName) {
              //         dis = true;
              //     }
              // });
              // return {
              //     disabled: dis,
              //     // Column configuration not to be checked
              //     name: record.userName,
              // }
            },
          }}
          rowKey="userName"
          width="100%"
          dataSource={dataSource}
          columns={isPC ? columnsPC : columnsMobile}
          loading={loader}
          pagination={false}
          size="small"
        />
      </Modal>
      {/* Message Error */}
      <Modal
        centered
        width={580}
        closeIcon={<></>}
        className="modal-msg modal-error"
        title={
          <div>
            <CloseCircleFilled /> {popupError.title}
          </div>
        }
        visible={popupError.show}
        footer={[
          <Button
            className="buttonPC button--error wAuto"
            onClick={() => {
              setPopupError({ ...popupError, show: false });
            }}
          >
            {lang.OK}
          </Button>,
        ]}
      ></Modal>
    </div>
  );
}

export default AutoMail;
