import Role from "./roles.const";
import React from "react";
// Role
import Role_list from "@src/page/Role_OF/list";

// Request
import Request_list from "@src/page/Request/list";
import Request_add from "@src/page/Request/add";
import Request_view from "@src/page/Request/view";
import Request_confirm from "@src/page/Request/confirm";

import Approval_list from "@src/page/Approval/list";
import Task_list from "@src/page/Task/list";
import Download_list from "@src/page/Download/list";

import { lang } from "@src/constants/common.const";
import { SettingFilled, HomeFilled } from "@ant-design/icons";
import { ROUTER_PAGE } from "@src/constants/router.const";
// Registration

export default [
  // Request management
  {
    component: Request_list,
    icon: <HomeFilled style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.REQUEST_LIST[0],
    title: lang.REQUEST_LIST,
    exact: true,
    key: "REQUEST_LIST",
  },
  {
    component: Request_add,
    path: "/CreateRequest",
    title: "Add Request",
    exact: true,
  },
  {
    component: Request_add,
    path: "/EditRequest/:id",
    title: "Edit Request",
    exact: true,
  },
  {
    component: Request_add,
    path: "/CloneRequest/:id",
    title: "Clone Request",
    exact: true,
  },
  {
    component: Request_view,
    path: "/ViewRequest/:id",
    title: "View Request",
    exact: true,
  },
  {
    component: Request_confirm,
    path: "/ConfirmRequest/:id",
    title: "Confirm Request",
    exact: true,
  },
  {
    component: Approval_list,
    path: ROUTER_PAGE.APPROVAL_LIST[0],
    title: lang.APPROVE_LIST,
    icon: <SettingFilled style={{ fontSize: "20px" }} />,
    exact: true,
    key: "APPROVAL_LIST",
  },
  {
    component: Request_add,
    path: "/EditRequestFlow/:id",
    title: "Edit Request Flow",
    exact: true,
  },
  {
    component: Task_list,
    path: ROUTER_PAGE.TASK_LIST[0],
    title: lang.TASK_LIST,
    icon: <SettingFilled style={{ fontSize: "20px" }} />,
    exact: true,
    key: "TASK_LIST",
  },
  {
    component: Download_list,
    path: ROUTER_PAGE.DOWNLOAD_LIST,
    title: lang.DOWNLOAD_LIST,
    icon: <SettingFilled style={{ fontSize: "20px" }} />,
    exact: true,
    key: "DOWNLOAD_LIST",
  },
  
  // role setting
  {
    component: Role_list,
    path: ROUTER_PAGE.ROLE_SETTING,
    title: lang.ROLE_SETTING,
    icon: <SettingFilled style={{ fontSize: "20px" }} />,
    exact: true,
    key: "ROLE_SETTING",
    //permission: [Role.HEAD_OFFICE_ADMIN],
  },
];
