import { logOut, getUser } from "@src/api/config";
import API from "@src/api/backend/auth";
import Axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import Roles from "@src/routers/roles/roles.const";
import { KEY } from "@src/constants/common.const";
import { updateCurrentUser, updateAndChangeCurrentRoute } from "./router";

function getUserName() {
  if (getUser()) {
    let username = getUser().userName.split("@");
    return username[0];
  } else {
    return null;
  }
}
const initialState = {
  userId: getUserName(),
  isAuth: false,
  username: "",
  shiId: "",
  displayName: "",
  status: "",
  error: "",
  factories: [],
  userGroups: [],
  statusNetwork: true,
  roles: null,
  isAdmin: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      logOut();
      delete Axios.defaults.headers.common["Authorization"];
    },
    setFactories: (state, { payload }) => {
      state.factories = payload;
    },
    setInfoUser: (state, { payload }) => {
      state.isAuth = true;
      state.username = payload.userName;
      state.shiId = payload.shiID;
      state.displayName = payload.displayName;
      const userRoles = Roles[Number(payload.role)];
      state.isAdmin = [Roles["1"]].includes(userRoles);
      state.roles = userRoles;
      localStorage.setItem("displayName", payload.displayName);
      localStorage.setItem(KEY.LOC_USER_INFO, JSON.stringify(payload));
    },
    changeMode: (state, { payload }) => {
      state.mode = payload;
    },
    changeStatusNetWork: (state, { payload }) => {
      state.statusNetwork = payload;
    },
  },
});
// the action
export const {
  logout,
  setFactories,
  setInfoUser,
  changeMode,
  changeStatusNetWork,
} = authSlice.actions;

// the selector
export const authSelector = (state) => state.auth;

//the reducer
export default authSlice.reducer;

export const getUserInfo = (beforeUser) => (dispatch) => {
  API.getUserInfo()
    .then((res) => {
      if (res && res.status === 200) {
        dispatch(setInfoUser(res.data));
        dispatch(updateCurrentUser(res.data.displayName));
        if (beforeUser === res.data.displayName) {
          dispatch(updateAndChangeCurrentRoute(false));
        } else {
          dispatch(updateAndChangeCurrentRoute(true));
        }
      }
    })
    .catch((err) => {
      //logOut();
    });
};
